import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout'
import { loadStripe } from "@stripe/stripe-js";
import globalVar from '../globalVar';
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "../components/stripeCheckout";
import { Divider } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const stripePromise = loadStripe(globalVar.stripe_Key_live);

function Pay(match) {
    const { sessionid } = useParams();
    const location = useLocation();
    //console.log(!location.state.ride)
    const reqobj = (!location.state.ride === false) ? location.state.ride : location.state;
    const [clientSecret, setClientSecret] = useState("");
    const [transID, setTransID] = useState("");
    const session = localStorage.getItem('session');
    const username = reqobj.user_name.split(" ");
    useEffect(() => {
        axios.post(`${globalVar.url}/secret`, { reqobj })
            .then((response) => {
                setClientSecret(response.data.clientSecret);
                setTransID(response.data.paymentIntentId);
            })
    }, []);
    reqobj.transactionID = transID;
    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };

    //console.log(clientSecret + '\n' + transID);
    
    const from = reqobj.bookedTimeSlots ? (moment(reqobj.bookedTimeSlots.from, "MM DD YYYY h:mm A").format("MMMM Do YYYY h:mm A")) : "";
    const to = reqobj.bookedTimeSlots ? (moment(reqobj.bookedTimeSlots.to, "MM DD YYYY h:mm a").format("MMMM Do YYYY h:mm A")) : "";

    return (
        <DefaultLayout>{session === sessionid ? 
            (reqobj.type !== "ride") ? 
            <div className='aboutBOX bxs p-3 mt-2 m-4 textColor'>
                <h4>Pay for {username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1)}'s Trip</h4>
                <Divider />
                <p className='text-start'><b>{(moment(new Date(reqobj.bookedTimeSlots.from)).format('MMM Do yyyy HH:mm A') - moment(new Date(reqobj.bookedTimeSlots.to)).format('MMM Do yyyy HH:mm A')) || (from + " - " + to)}</b></p>
                <p className='text-start'><b>Pick Up Address:</b> {reqobj.location}</p>
                <p className='text-start'><b>Trip Mileage:</b> {reqobj.mileage}</p>
                <p className='text-start'><b>Rate</b> - $ {Math.ceil((reqobj.carRate * reqobj.totalDays) * 100 / 100).toFixed(2)} ($ {reqobj.carRate} x {reqobj.totalDays}) Days</p>
                <p className='text-start'><b>Car Deposit</b> (Subject to Incidental Trip Charges) - $ {Math.ceil(reqobj.deposit * 100) / 100}</p>
                {reqobj.u25FeeRequired ? <p className='text-start'><b>Under 25 Fee</b> - $ {reqobj.u25_fee}</p> : ""}
                {reqobj.deliveryRequired ? <p className='text-start'><b>Delivery</b> - $ {'40.00'}</p> : ""}
                {reqobj.extras.fuel ? <p className='text-start'><b>Prepaid Fuel</b> - $ {'40.00'}</p> : ""}
                {reqobj.extras.charge ? <p className='text-start'><b>Prepaid Charging</b> - $ {'25.00'}</p> : ""}
                {reqobj.extras.toll ? <p className='text-start'><b>Unlimited Toll Access</b> - $ {'15.00'}</p> : ""}
                {reqobj.extras.clean ? <p className='text-start'><b>Post-Trip Cleaning</b> - $ {'20.00'}</p> : ""}
                <p className='text-start'><b>Subtotal</b> - $ {reqobj.subTotal.toFixed(2)}</p>
                <p className='text-start'><b>Taxes</b> - $ {(Math.round((reqobj.total - reqobj.subTotal) * 100) / 100).toFixed(2)}</p>
                <Divider />
                <p className='text-start pb-3'><b>Total</b> - $ {reqobj.total.toFixed(2)}</p>
                {stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret, }}>
                        <StripeCheckout state={[reqobj]}  style={{border: 'solid yellow'}}/>
                    </Elements>
                )}
            </div> : <div className='aboutBOX bxs p-3 mt-2 m-4 textColor'>
                <h4>Pay for {username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1)}'s Ride</h4>
                <Divider />
                <p className='text-start'>{reqobj.date}</p>
                <p className='text-start'><b>Origin:</b> {reqobj.from}</p>
                <p className='text-start'><b>Destination:</b> {reqobj.to}</p>
                <p className='text-start'><b>Distance:</b> {reqobj.results.distance}</p>
                <p className='text-start'><b>Duration:</b> {reqobj.results.duration}</p>
                <p className='text-start'><b>Subtotal</b> - $ {reqobj.offer.toFixed(2)}</p>
                <p className='text-start'><b>Taxes</b> - $ {reqobj.taxes.toFixed(2)}</p>
                <Divider />
                <p className='text-start pb-3'><b>Total</b> - $ {reqobj.total.toFixed(2)}</p>
                {stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret, }}>
                        <StripeCheckout state={[reqobj]}  style={{border: 'solid yellow'}}/>
                    </Elements>
                )}
            </div>
            : <h2>Invalid Session</h2>}
        </DefaultLayout>
    )
}

export default Pay;