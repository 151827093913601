import React, { useEffect, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCars } from '../redux/actions/carsActions';
import { Link, useParams } from 'react-router-dom';
import { Form, Select, Checkbox, Divider, message, Carousel } from 'antd';
//DatePicker,
import Spinner from '../components/Spinner';
import arrow from '../assests/left-arrow.png';
import moment from 'moment';
import PlacesAutocomplete from 'react-places-autocomplete';
import gas from '../assests/gas-can.png';
import seat from '../assests/car-seat.png';
import elec from '../assests/car.png';
import remote from '../assests/remote.png';
import lock from '../assests/lock.png';
import { Datepicker } from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

//const { RangePicker } = DatePicker

function Bookingcar({ match }) {
    const { vehicleid } = useParams();
    const { cars } = useSelector(state => state.carsReducer);
    const { loading } = useSelector(state => state.alertsReducer);
    const today = new Date().setHours(0, 0, 0, 0);
    const dispatch = useDispatch();
    const [car, setcar] = useState([]);
    const [from, setfrom] = useState();
    const [to, setTo] = useState();
    const [totalHours, settotalHours] = useState(0);
    const [totalDays, settotalDays] = useState(0);
    const [delivery, setDelivery] = useState(false);
    const [subTotal, setsubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [mileage, setTotalMileage] = useState(0);
    const [address, setAddress] = useState("5000 North Mays Street, Round Rock, Texas, USA");
    const [sessionID, setSessionID] = useState("");
    const [preGas, setPregas] = useState(false);
    const [preElec, setPreElec] = useState(false);
    const [validDate, setvalidDate] = useState(false);
    const [toll, setToll] = useState(false);
    const [taxes, setTax] = useState(0);
    const [pick, setpick] = useState();
    const [clean, setClean] = useState(false);
    const [u25, setu25] = useState(false);

    const loggedIN = JSON.parse(localStorage.getItem('user'));
    var years = moment().diff(loggedIN.dob, 'years');
    const amount = years < 25 ? car.u25_fee : 0;



    function makeSessionid(length) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        setSessionID(makeSessionid(7));
        if (years < 25) {
            setu25(true);
        }
        if (cars.length == 0) {
            dispatch(getAllCars());
        }
        else {
            setcar(cars.find(o => o._id === vehicleid));
        }
    }, [cars])

    useEffect(() => {
        if (pick !== "pickup") {
            setDelivery(true)
        }
        else {
            setDelivery(false)
        }
    }, [pick])

    const updatedBlocked = car.bookedTimeSlots?.map((slots) => ({
        start: slots.from,
        end: slots.to
    }));


    useEffect(() => {
        setsubTotal((totalDays * car.rentPerDay) + car.deposit + amount + (delivery ? 40 : 0));
    }, [totalDays, delivery])


    useEffect(() => {
        setTax(salesTaxRate * subTotal);
    }, [taxes, subTotal])

    useEffect(() => {
        setTotalMileage(car.miles * totalDays);
    }, [mileage, totalDays])

    const handleSelect = async (value) => {
        setAddress(value);
    }

    const handlePick = (value) => {
        setpick(value)
    }

    useEffect(() => {
        setTotal(subTotal + taxes);
    }, [total, taxes])

    function selectTimeSlots(values) {
        setfrom(moment(values.value[0]).format("MM DD yyyy h:mm A"));
        setTo(moment(values.value[1]).format("MM DD yyyy h:mm A"));

        const one = from;
        const two = moment(values.value[1]).format("MM DD yyyy h:mm A");
        const hours = moment(values.value[1]).diff(moment(values.value[0]), 'hours');
        const days = moment(values.value[1]).diff(moment(values.value[0]), 'days');

        //console.log(values.value);
        //console.log(one + '\n' + two);
        //console.log(hours);
        //console.log(days);

        settotalHours(hours);

        if (hours < 24) {
            settotalDays('1');
        }
        else {
            const newDays = (hours / 24);
            settotalDays(Math.ceil(newDays));
        }

        setvalidDate(true);
    }

    function validDates(values) {
        if (values == null) {
            document.getElementsByClassName("btm1").style.visibility = "hidden";
            message.error(
                "Please enter valid dates."
            )
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        //return current < dayjs().endOf('day');
        return current < today;
    };

    const salesTaxRate = 8.25 / 100;
    localStorage.setItem("session", sessionID);
    const session = localStorage.getItem("session");

    return (
        <DefaultLayout>
            {loading === true && (<Spinner />)}

            <div className='row m-1' >
                <div className='col-md-12 d-flex justify-content-start'>
                    <a href='/rent'><img src={arrow} width='15' /></a>
                </div>
            </div>

            <div className='container textColor'>
                <div className='row'>
                    <div className='bookImage col-md-6 p-2 block'>
                        <Carousel autoplay="true">
                            {car.images?.map((image) => {
                                return (
                                    <div>
                                        <img src={image.url} className="carimg2 d-block img-fluid" />
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div className='col-md-6'>
                        <div>
                            <h4>{car.name}</h4>
                        </div>
                        <div>
                            <p>Features</p>
                            <div className='featBorder'>
                                <div className="d-inline-flex pt-2">
                                    <div className='d-flex flex-column align-items-center pe-4'>
                                        <img src={seat} width="30" />
                                        <p>{car.capacity}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center pe-4'>
                                        {car.fuelType === 'Electric' ? <img src={elec} width="30" /> : <img src={gas} width="30" />}
                                        <p>{car.fuelType}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        {car.accessType === 'lockbox' ? <img src={lock} width="30" /> : <img src={remote} width="30" />}
                                        {car.accessType === "remote" ? <p>Remote Unlock</p> : <p>Lockbox Key Access</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>Trip Dates</p>
                            <div className='featBorder p-3' id="picker">
                                <Datepicker responsive={{
                                    small: {
                                        display: 'center',
                                        pages: 1,
                                        touchUi: true,
                                    },
                                }} invalid={updatedBlocked} themeVariant='light' min={today} touchUi={true} theme="ios" controls={['calendar', 'time']} select="range" onChange={selectTimeSlots} />
                            </div>
                        </div>
                        <div>
                            <p>Pickup &amp; Return</p>
                            <div className='featBorder'>
                                <Select className='m-2 bookPickUPbox'  options={[
                                    { value: 'pickup', label: "Pick Up" },
                                    { value: 'deliver', label: "Delivery" },
                                    { value: 'airport', label: "Austin Bergstrom Airport" },
                                ]} onChange={handlePick} />
                                {pick === "pickup" ? <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input className='LocateInput' {...getInputProps({ placeholder: "Enter Address..." })} disabled />
                                        {setAddress("5000 North Mays Street, Round Rock, Texas, USA")}
                                    </div>
                                )}</PlacesAutocomplete> : pick === "deliver" ? <PlacesAutocomplete highlightFirstSuggestion value={address} onChange={setAddress} onSelect={handleSelect}>{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input className='LocateInput' {...getInputProps({ placeholder: "Enter Address..." })} />
                                        <div>
                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                    backgroundColor: suggestion.active ? "#1e90ff" : "#ffffff"
                                                }
                                                return (<div {...getSuggestionItemProps(suggestion, { style })}>
                                                    {suggestion.description}</div>)
                                            })}
                                        </div>
                                    </div>
                                )}</PlacesAutocomplete> : pick === "airport" ? <PlacesAutocomplete value="Austin-Bergstrom International Airport (AUS), Presidential Boulevard, Austin, TX, USA" onChange={setAddress} onSelect={handleSelect}>{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input className='LocateInput' {...getInputProps({ placeholder: "Enter Address..." })} disabled />
                                        {setAddress("Austin-Bergstrom International Airport (AUS), Presidential Boulevard, Austin, TX, USA")}
                                    </div>
                                )}</PlacesAutocomplete> : ""}
                            </div>
                        </div>
                        <div>
                            <p>Extras</p>
                            <div className="featBorder d-flex flex-column align-items-center">
                                <div className="d-flex flex-column align-items-start">
                                    <div>
                                        {car.fuelType !== 'Electric' ?
                                            <Checkbox onChange={(e) => {
                                                setPreElec(false);
                                                if (e.target.checked) {
                                                    setPregas(true);
                                                    setsubTotal(subTotal + 40);
                                                }
                                                else {
                                                    setPregas(false);
                                                    setsubTotal(subTotal - 40);
                                                }
                                            }}>Prepaid Fuel</Checkbox> : <Checkbox onChange={(e) => {
                                                setPregas(false);
                                                if (e.target.checked) {
                                                    setPreElec(true);
                                                    setsubTotal(subTotal + 25);
                                                }
                                                else {
                                                    setPreElec(false);
                                                    setsubTotal(subTotal - 25);
                                                }
                                            }}>Prepaid Electric Charge</Checkbox>}
                                    </div>
                                    <div>
                                        <Checkbox onChange={(e) => {
                                            if (e.target.checked) {
                                                setToll(true);
                                                setsubTotal(subTotal + 20);
                                            }
                                            else {
                                                setToll(false);
                                                setsubTotal(subTotal - 20);
                                            }
                                        }}>Unlimited Toll Access</Checkbox>
                                    </div>
                                    <div>
                                        <Checkbox onChange={(e) => {
                                            if (e.target.checked) {
                                                setClean(true);
                                                setsubTotal(subTotal + 15);
                                            }
                                            else {
                                                setClean(false);
                                                setsubTotal(subTotal - 15);
                                            }
                                        }}>Prepaid Post-Trip Cleaning</Checkbox>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {loggedIN.dob ? (from && to && (<div>
                                <p className='text-end'><b>Pick Up Address:</b> {address}</p>
                                <p className='text-end'><b>Trip Mileage:</b> {mileage}</p>
                                <p className='text-end'><b>Daily Rate</b> - $ {Math.ceil(car.rentPerDay * 100) / 100} x {totalDays} Days</p>
                                <p className='text-end'><b>Car Deposit</b> (Subject to Incidental Trip Charges) - $ {Math.ceil(car.deposit * 100) / 100}</p>
                                {years < 25 ? <p className='text-end'><b>Under 25 Fee</b> - $ {car.u25_fee}</p> : ""}
                                {delivery ? <p className='text-end'><b>Delivery Fee</b> - $ {'40'}</p> : ""}
                                {preGas ? <p className='text-end'><b>Prepaid Fuel</b> - $ {'40.00'}</p> : ""}
                                {preElec ? <p className='text-end'><b>Prepaid Charging</b> - $ {'25.00'}</p> : ""}
                                {toll ? <p className='text-end'><b>Unlimited Toll Access</b> - $ {'15.00'}</p> : ""}
                                {clean ? <p className='text-end'><b>Post-Trip Cleaning</b> - $ {'20.00'}</p> : ""}
                                <p className='text-end'><b>Subtotal</b> - $ {subTotal.toFixed(2)}</p>
                                <p className='text-end'><b>Taxes</b> - $ {(Math.round(taxes * 100) / 100).toFixed(2)}</p>
                                <Divider />
                                <p className='text-end'><b>Total</b> - $ {(total).toFixed(2)}</p>
                                <div className='d-flex justify-content-end pt-3'>
                                    <button className='btm1'>
                                        <Link style={{ textDecoration: "none" }} to={`/pay/${sessionID}`} state={{
                                            user: JSON.parse(localStorage.getItem('user'))._id,
                                            user_name: JSON.parse(localStorage.getItem('user')).name,
                                            email: loggedIN.email,
                                            name: car.name,
                                            vehicle: car._id,
                                            totalDays,
                                            carRate: car.rentPerDay,
                                            totalHours,
                                            subTotal,
                                            deposit: car.deposit,
                                            total: (Math.ceil(total * 100) / 100),
                                            unit_amount: total,
                                            mileage,
                                            type: car.type,
                                            deliveryRequired: delivery,
                                            u25FeeRequired: u25,
                                            completed: false,
                                            u25_fee: car.u25_fee,
                                            bookedTimeSlots: {
                                                from,
                                                to
                                            },
                                            location: address,
                                            extras: {
                                                fuel: preGas,
                                                charge: preElec,
                                                toll: toll,
                                                clean: clean
                                            },
                                            image: car.images[0].url,
                                        }}>Pay Now</Link>
                                    </button>
                                </div>

                            </div>)) : (<div><p className='text-end'><b>Please Enter Your Date of Birth</b><br />(My Info Section)</p>
                                <div className='d-flex justify-content-end pt-3'><button className='btm1'><Link to="/mystuff" style={{ textDecoration: "none" }}>Here</Link></button></div></div>)}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Bookingcar