import { Button, Col, Form, Input, Radio, Row, Space, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import FormList from 'antd/lib/form/FormList';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import Spinner from '../components/Spinner';
import { editCar } from '../redux/actions/carsActions';
import { useParams } from 'react-router-dom';
import { getAllCars } from '../redux/actions/carsActions';
import { CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getAllUsers, editUser, addUser, deleteUser } from '../redux/actions/userActions';

function EditCar() {
    const { Option } = Select;
    const { carid } = useParams();
    const { cars } = useSelector(state => state.carsReducer)
    const { users } = useSelector(state => state.usersReducer);
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.alertsReducer)
    const [car, setCar] = useState()
    const [totalCars, setTotalCars] = useState([])
    const [radio, setRadio] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const [type, setType] = useState();

    const onChange = (e) => {
        setRadio(e.target.value);
    };

    useEffect(() => {

        dispatch(getAllUsers())
        if (cars.length === 0) {
            dispatch(getAllCars());
        }
        else {
            setTotalCars(cars)
            setCar(cars.find(o => o._id === carid))
        }
    }, [cars])

    function onFinish(values) {
        values._id = car._id;
        ((values.type === "boat") ? values.miles = 0 : values.hours = 0)
        dispatch(editCar(values))
        //console.log(values);
    }
    return (
        <DefaultLayout>
            {loading === true && (<Spinner />)}
            <Row className="flex justify-content-center">
                <Col lg={12} sm={24} style={{ width: "95%" }}>
                    {totalCars.length > 0 && (<Form initialValues={car} className='bxs p-3' layout='vertical' onFinish={onFinish}>
                        <h3>Edit Vehicle</h3>
                        <hr />
                        <Form.Item name='owner' label="Host" rules={[{ required: true }]}>
                            {user?.role === "system_admin" ?
                                <Select className='d-flex justify-content-start' style={{ width: "100%" }} onChange={(e) => setType(e)}>
                                    {users.map((user) => {
                                        return (<Option value={user._id}>{user.name}</Option>)
                                    })}
                                </Select>
                                : <Input disabled />}
                        </Form.Item>
                        <Form.Item name='name' label="Vehicle Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='type' label="Vehicle Type" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name='plate' label="License Plate" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name='accessType' label="Car Access" rules={[{ required: true }]}>
                            <Radio.Group className='d-flex justify-content-start' onChange={onChange} value={radio}>
                                <Space direction="vertical" style={{ alignItems: "flex-start" }}>
                                    <Radio value="lockbox">Lockbox Key Access</Radio>
                                    <Radio value="remote">Remote Unlock</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name='rentPerDay' label="Daily Rate" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        {car.type == "car" ? <Form.Item name='miles' label="Daily Mileage" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item> : <Form.Item name='hours' label="Rental Length (Hours)" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        }
                        <Form.Item name='capacity' label="Capacity" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='deposit' label="Deposit Amount" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='u25_fee' label="Under 25 Fee Amount" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='fuelType' label="Fuel Type" rules={[{ required: true }]}>
                            <Radio.Group className='d-flex justify-content-start' onChange={onChange} value={radio}>
                                <Space direction="vertical" style={{ alignItems: "flex-start" }}>
                                    <Radio value="Regular/Unleaded">Regular/Unleaded</Radio>
                                    <Radio value="Premium">Premium</Radio>
                                    <Radio value="Electric">Electric</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <FormList name={"images"}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => {
                                        return (
                                            <Space
                                                key={field.key}
                                                direction="vertical"
                                                style={{ display: "flex" }} >
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "url"]}
                                                    label={`Image ${index + 1}`}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Image URL is required",
                                                        },
                                                    ]} >
                                                    <div className='d-flex justify-content-between'>
                                                        <Input value={car.images[field.key]?.url} style={{ width: "90%" }} />
                                                        {fields.length > 1 ? (
                                                            <CloseOutlined
                                                                className="dynamic-delete-button"
                                                                style={{ color: "red" }}
                                                                onClick={() => { remove(field.name); }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>
                                            </Space>

                                        );
                                    })}
                                    < FormItem >
                                        <Button
                                            type="dashed"
                                            block
                                            onClick={() => add()}
                                        >
                                            + Add Image
                                        </Button>
                                    </FormItem>
                                </>

                            )}

                        </FormList>

                        <div className='d-flex justify-content-end'>
                            <button className='btm1'>Edit Vehicle</button>
                        </div>
                    </Form>)}
                </Col>
            </Row >
        </DefaultLayout >
    )
}

export default EditCar