import { Divider } from 'antd'
import React, { useEffect } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import GLD from '../assests/gld.png';
import SLV from '../assests/slv.png';
import BRNZ from '../assests/brnz.png';

function Advertise() {
    useEffect(() => {
        document.title = "Austin Vehicle Advertising | Rora"
    }, [])
    return (
        <DefaultLayout>
            <div className='container textColor'>
                <div className='bxs p-3 mt-3'>
                    <h3>Advertising Tiers</h3>
                    <Divider />
                    <div className="row p-3 pb-5 pt-3 mt-2">
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className="pb-2" src={BRNZ} width="50" />
                                <h5>Bronze Tier</h5>
                                <Divider/>
                                <p>
                                    <ul>
                                        <li>Economy Vehicle</li>
                                        <li>Magnetic Signage on 2 Doors</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className="pb-2" src={SLV} width="50" />
                                <h5>Silver Tier</h5>
                                <Divider/>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className="pb-2" src={GLD} width="50" />
                                <h5>Gold Tier</h5>
                                <Divider/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Advertise