import axios from "axios";
import { message } from "antd";

//let url = "http://localhost:4000";
 let url = "https://api.roraoftexas.com";

export const getOnlineDrivers = () => async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
  
    try {
      const response = await axios.get(`${url}/api/driver/getonlinedrivers`);
      dispatch({ type: "GET_ONLINE_DRIVERS", payload: response.data });
      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  export const driverOffline = (reqobj) => async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
  
    try {
      await axios.post(`${url}/api/driver/driveroffline`, reqobj);
      dispatch({ type: "LOADING", payload: false });
      message.success("Driver Offline");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  export const goOnline = (reqobj) => async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });

    try {
      await axios.post(`${url}/api/driver/goonline`, reqobj);
      dispatch({ type: "LOADING", payload: false });
      message.success("Driver Online");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
}

  export const updateDriver = (reqobj) => async (dispatch) => {
    dispatch({ type: "LOADING", payload: true });
    
    try {
      await axios.post(`${url}/api/driver/updateDriverPosition`, reqobj);
      dispatch({ type: "LOADING", payload: false });
      //message.success("Driver Online");
      /* setTimeout(() => {
        window.location.reload();
      }, 500); */
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };