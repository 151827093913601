import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefaultLayout from "../components/DefaultLayout";
import { getAllCars } from "../redux/actions/carsActions";
import { Col, Row, DatePicker, Space, Dropdown } from "antd";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import moment from "moment";
import isBetween from "moment";
import { Datepicker } from "@mobiscroll/react";
import Filter from '../assests/filter.png';
import Filtered from '../assests/filtered.png';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import dayjs from "dayjs";
//import isBetween from 'dayjs/plugin/isBetween'

function Car() {
  const { cars } = useSelector((state) => state.carsReducer);
  const { loading } = useSelector((state) => state.alertsReducer);
  const [totalCars, settotalCars] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const today = new Date().setHours(0, 0, 0, 0);
  const today2 = moment().format("MM DD yyyy h:mm")
  const dispatch = useDispatch();
  var now = dayjs().format();
  var isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  useEffect(() => {
    dispatch(getAllCars());
    document.title = "Austin Car and Boat Rentals | Rora"
  }, []);

  useEffect(() => {
    settotalCars(cars);
  }, [cars]);

  const handleClick = () => {
    setOpen(!open)
  }

  function setFilter(values) {
    if (values == null) {
      settotalCars(cars);
    }
    else {
      var selectedFrom = dayjs(values.value[0]).format();
      var selectedTo = dayjs(values.value[1], "MM-DD-YYYY").format();
      var temp = [];
      var remove;
      for (var car of cars) {
        if (car.bookedTimeSlots.length == 0) {
          temp.push(car);
        } else {
          for (var booking of car.bookedTimeSlots) {
            const Bfrom = dayjs(booking.from, "MM-DD-YYYY").format()
            const BTo = dayjs(booking.to, "MM-DD-YYYY").format()
            if (
              dayjs(selectedFrom).isBetween(Bfrom, BTo) || dayjs(selectedTo).isBetween(Bfrom, BTo)
            ) {
              temp = temp.filter(function (item) {
                return item !== car
              })
              continue;
            } else {
              temp.push(car);
            }
          }
        }
      }
      let newTemp = []
      temp.forEach(element => {
        if (!newTemp.includes(element)) {
          newTemp.push(element)
        }
      });
      settotalCars(newTemp);
    }
  }

  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => {
          setSearchFilter("auto");
        }}>
          Automobiles
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={() => {
          setSearchFilter("water");
        }}>
          Water Vehicles
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a onClick={() => {
          setSearchFilter("");
        }}>
          Clear
        </a>
      ),
    },
  ];

  function isRented(current, date1, date2) {
    current = now;
    var from2 = dayjs(date1, "MM-DD-YYYY").format();
    var to2 = dayjs(date2, "MM-DD-YYYY").format();
    if (now > from2 && now < to2)
      return true;
  }

  return (
    <DefaultLayout>
      <div className="container">
        <div className="d-flex justify-content-around">
          <div className="filterpicker">
            <Datepicker className="" inputProps={{
              placeholder: 'Please Select Trip Dates...'
            }} responsive={{
              small: {
                display: 'center',
                pages: 1,
                touchUi: true,
              },
            }} themeVariant='light' min={today} touchUi={true} theme="ios" controls={['calendar', 'time']} select="range" onChange={setFilter} />
          </div>
          <div className="d-flex align-items-center">
            <Space direction="vertical">
              <Space wrap>
                <Dropdown menu={{ items }} placement="bottom">
                  <a style={{ textDecoration: "none", color: "dodgerblue" }}><img src={searchFilter ? Filtered : Filter} width="30" style={{ cursor: "pointer" }} />Filter</a>
                </Dropdown>
              </Space>
            </Space>
          </div>
        </div>

        {loading === true && <Spinner />}

        <Row justify="center" gutter={16}>
          {searchFilter !== "" ? (searchFilter === "auto" ?
            totalCars.filter(o => o.type === "car").map((car) => {
              return (
                <Col lg={5} sm={24} xs={24} className="d-flex justify-content-center">
                  <div className="car p-2 bxs2">
                    <img className="carimg" src={car.images[0].url} />
                    <div className="car-content d-flex align-items-center justify-content-between">
                      <div>
                        <div>
                          <p className="textColor">{car.name}</p>
                        </div>
                        <div>
                          <p className="textColor">${car.rentPerDay} / Day</p>
                        </div>
                      </div>
                      <div>
                        {/* {isRented(now, car.bookedTimeSlots[car.bookedTimeSlots.length-1]?.from, car.bookedTimeSlots[car.bookedTimeSlots.length-1]?.to) ?
                          <button className="btm3 mr-2" disabled>
                            Rented
                          </button>
                          :
                          <Link className="btm1 p-1 mr-2" to={`/booking/${car._id}`} style={{ textDecoration: "none" }}>Book Now</Link>
                        } */}
                      </div>
                    </div>
                  </div>

                </Col>
              )
            })
            : totalCars.filter(o => o.type !== "car").map((car) => {
              return (
                <Col lg={5} sm={24} xs={24} className="d-flex justify-content-center">
                  <div className="car p-2 bxs2">
                    <img className="carimg" src={car.images[0].url} />
                    <div className="car-content d-flex align-items-center justify-content-between">
                      <div>
                        <div>
                          <p className="textColor">{car.name}</p>
                        </div>
                        <div>
                          <p className="textColor">${car.rentPerDay} / Day</p>
                        </div>
                      </div>
                      <div>
                        {/* {isRented(now, car.bookedTimeSlots[car.bookedTimeSlots.length-1]?.from, car.bookedTimeSlots[car.bookedTimeSlots.length-1]?.to) ?
                          <button className="btm3 mr-2" disabled>
                            Rented
                          </button>
                          :
                          <Link className="btm1 p-1 mr-2" to={`/booking/${car._id}`} style={{ textDecoration: "none" }}>Book Now</Link>
                        } */}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }))
            : (totalCars.map((car) => {
              return (
                <Col lg={5} sm={24} xs={24} className="d-flex justify-content-center">
                  <div className="car p-2 bxs2">
                    <img className="carimg" src={car.images[0].url} />
                    <div className="car-content d-flex align-items-center justify-content-between">
                      <div>
                        <div>
                          <p className="textColor">{car.name}</p>
                        </div>
                        <div>
                          <p className="textColor">${car.rentPerDay} / Day</p>
                        </div>
                      </div>
                      <div>
                        {/* {console.log(car.name)} */}
                        {/* {console.log(car.bookedTimeSlots[car.bookedTimeSlots.length-1])} */}
                        {isRented(now, car.bookedTimeSlots[car.bookedTimeSlots.length - 1]?.from, car.bookedTimeSlots[car.bookedTimeSlots.length - 1]?.to) ?
                          <button className="btm3 mr-2" disabled>
                            Rented
                          </button>
                          :
                          <Link className="btm1 p-1 mr-2" to={`/booking/${car._id}`} style={{ textDecoration: "none" }}>Book Now</Link>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              );
            }))}
        </Row>
      </div>
    </DefaultLayout>
  );
}

export default Car;
