import axios from "axios";
import { message } from "antd";

//let url = "http://localhost:4000";
 let url = "https://api.roraoftexas.com";

export const bookCar = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });

  try {
    await axios.post(`${url}/api/bookings/bookcar`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("Your car booked Successfully");
    return true;
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};
export const editBooking = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });

  try {
    await axios.post(`${url}/api/bookings/editbooking`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("The Booking was Edited Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const getAllBookings = () => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });

  try {
    const response = await axios.get(`${url}/api/bookings/getallbookings`);
    dispatch({ type: "GET_ALL_BOOKINGS", payload: response.data });
    dispatch({ type: "LOADING", payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
  }
};
