import { Button, Col, Form, Input, Radio, Row, Select, Space, Option } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import FormList from 'antd/lib/form/FormList';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import { getAllUsers } from '../redux/actions/userActions';
import Spinner from '../components/Spinner';
import { addCar } from '../redux/actions/carsActions';
import { CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';

function AddCar() {
    const { Option } = Select;
    const dispatch = useDispatch()
    const { loading } = useSelector(state => state.alertsReducer);
    const { users } = useSelector(state => state.usersReducer);
    const [radio, setRadio] = useState(0);
    const [type, setType] = useState();
    const user = JSON.parse(localStorage.getItem("user"));

    const onChange = (e) => {
        setRadio(e.target.value);
    };

    useEffect(() => {
        dispatch(getAllUsers())
    }, [])

    function onFinish(values) {
        values.bookedTimeSlots = [];
        values.type === "boat" ? values.miles = 0 : values.hours = 0;
        dispatch(addCar(values))
        console.log(values);
    }

    return (
        <DefaultLayout>
            {loading === true && (<Spinner />)}
            <Row className="flex justify-content-center">
                <Col lg={12} sm={24} style={{ width: "95%" }}>
                    <Form className='bxs p-3' layout='vertical' onFinish={onFinish}>
                        <h3>Add New Vehicle</h3>
                        <hr />
                        <Form.Item name='owner' label="Host" rules={[{ required: true }]}>
                            {user?.role === "system_admin" ?
                                <Select className='d-flex justify-content-start' style={{ width: 200 }} onChange={(e) => setType(e)}>
                                    {users.map((user) => {
                                        return (<Option value={user._id}>{user.name}</Option>)
                                    })}
                                </Select>
                                :
                                <Input value={user._id} placeholder={user._id} disabled />}
                        </Form.Item>
                        <Form.Item name='name' label="Vehicle Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='type' label="Vehicle Type" rules={[{ required: true }]}>
                            <Select className='d-flex justify-content-start' style={{ width: 130 }} onChange={(e) => setType(e)}>
                                <Option value="car">Car</Option>
                                <Option value="boat">Boat</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name='accessType' label="Key Access" rules={[{ required: true }]}>
                            <Select className='d-flex justify-content-start' style={{ width: "175px" }} onChange={(e) => setType(e)}>
                                <Option value="remote">Remote Unlock</Option>
                                <Option value="lockbox">Lockbox Key Access</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name='plate' label="License Plate" rules={[{ required: true }]}>
                            <Input className='toUpper' maxLength="7" />
                        </Form.Item>
                        <Form.Item name='rentPerDay' label="Daily Rate" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        {type != "boat" ? <Form.Item name='miles' label="Daily Mileage" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item> : <Form.Item name='hours' label="Rental Length (Hours)" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>}
                        <Form.Item name='capacity' label="Capacity" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='deposit' label="Deposit Amount" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='u25_fee' label="Under 25 Fee Amount" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='fuelType' label="Fuel Type" rules={[{ required: true }]}>
                            <Radio.Group className='d-flex justify-content-start' onChange={onChange} value={radio}>
                                <Space direction="vertical" style={{ alignItems: "flex-start" }}>
                                    <Radio value="Regular/Unleaded">Regular/Unleaded</Radio>
                                    <Radio value="Premium">Premium</Radio>
                                    <Radio value="Electric">Electric</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <FormList name={"images"}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => {
                                        //console.log(field)
                                        return (
                                            <Space key={field.key} direction='vertical' style={{display: "flex"}}>
                                                <Form.Item name={[field.name, "url"]} label={`Image ${index + 1}`} rules={[{ required: true }]} >
                                                    <div className='d-flex justify-content-between'>
                                                        <Input style={{ width: "90%" }} placeholder="Image URL" />
                                                        {fields.length > 1 ? (
                                                            <CloseOutlined
                                                                className="dynamic-delete-button"
                                                                style={{ color: "red" }}
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </Form.Item>

                                            </Space>
                                        );
                                    })}
                                    <FormItem>
                                        <Button type="dashed" block onClick={() => { add(); }}>+ Add Image</Button>
                                    </FormItem>
                                </>
                            )}

                        </FormList>


                        <div className='d-flex justify-content-end'>
                            <button className='btm1'>Add Vehicle</button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </DefaultLayout>
    )
}

export default AddCar