import { Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ML from '../assests/rora mini.png';
import { getAllUsers, verifyUser } from '../redux/actions/userActions';

function Verify() {
  const { users } = useSelector(state => state.usersReducer);
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  
  useEffect(() => {
    dispatch(getAllUsers())
  }, [])
  var count = 0;

  function onFinish(values) {
    users.map((user) => {
      if (user.code === values.code) {
        user.verified = true;
        dispatch(verifyUser(user));
        setValid(user.verified);
        count++;
      }
    })
    if (count === 0)
    message.error("Invalid Code");
  }

  return (
    <div className="bxs p-3 m-5">
      <img id="logimg" src={ML} width="250" />
      <h4 className='textColor'>Enter Code to Verify Your Account</h4>
      <div className='container' style={{ width: "75%", }}>
        <Form layout='vertical' onFinish={onFinish}>
          <Form.Item name="code">
            <Input />
          </Form.Item>
          <div className='d-flex justify-content-center'>
            <button className='btm1' disabled={valid ? true : false}>Verify</button>
          </div>
        </Form>
      </div>
    </div>


  )
}

export default Verify