import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Collapse, Divider, Space, Typography } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

function FAQ() {

    return (
        <DefaultLayout>
            <div className='container'>
                <div className='bxs p-3'>
                    <h5 className='textColor'>FAQ</h5>
                    <Divider/>
                    <Space direction="vertical">
                        <Collapse collapsible='header' size='small' items={[
                            {
                                key: '1',
                                label: 'Our Rental Rules',
                                children: <p>- Must have Full Coverage Insurance<br/>- Deposit is Required</p>,
                            },
                            {
                                key: '2',
                                label: 'Charges & Violation Fees',
                                children: <><p><u>Charges</u><br/>
                                - Cost of your rental<br/>
                                - Delivery (if selected)<br/>
                                - Deposit (refunded pending no violations)<br/>
                                - Taxes<br/>
                                - Under 25 Fee (if applies)<br/>
                                - Extras (if selected):<br/>&nbsp;&nbsp;&nbsp;Toll Access<br/>&nbsp;&nbsp;&nbsp;Prepaid Fuel/Electric Charge<br/>&nbsp;&nbsp;&nbsp;Post-Trip Cleaning</p>
                                <br/><p><u>Violation Fees</u><br/>
                                - Toll (if no Toll Access Extra):<br/>&nbsp;&nbsp;&nbsp;Toll charges + $15 Inconvenience Fee<br/>
                                - Inadequate Fuel or Electric Charge<br/>(Rental must be returned at same amount of fuel or electric charge):<br/>
                                &nbsp;&nbsp;&nbsp;Cost of gas/charging + $10 Inconvenience Fee<br/>
                                - Cleaning/Smoking Violation:<br/>&nbsp;&nbsp;&nbsp;Subject up to $150<br/>
                                - Late Fee (if rental is returned late):<br/>
                                &nbsp;&nbsp;&nbsp;$30 under 30min, $45 per 30min<br/>
                                </p></>,
                            },
                            {
                                key: '3',
                                label: 'Refund Policy',
                                children:
                                <>
                                <p><b>Refund Policy</b><br/>
                                - <u>NO REFUNDS</u> for cancellations within 24 hours or less of trip start time.<br/>
                                - <u>PARTIAL REFUNDS</u> for cancellations between 36-24 hours before trip start.<br/>
                                - <u>FULL REFUNDS</u> for cancellations 36+ hours before trip start.</p>
                                </>,
                            },
                            {
                                key: '4',
                                label: 'Do you have to be 25+?',
                                children: <p>We do not require a renter to be 25+ years of age, however there is an Under 25 Fee (Fee amount varies on vehicle)</p>,
                            },
                            ]} 
                        style={{width:"350px"}}/>
                    </Space>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default FAQ