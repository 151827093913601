import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DefaultLayout from '../components/DefaultLayout';
import { deleteCar, getAllCars } from '../redux/actions/carsActions';
import { DatePicker, Col, Row, Divider, Form, Input, message, Popconfirm, Select, Modal, Switch, Button, Radio, Tabs } from 'antd';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAllBookings } from '../redux/actions/bookingActions';
import { getAllUsers, editUser, addUser, deleteUser } from '../redux/actions/userActions';
import { getAllCustomers } from '../redux/actions/customerActions';
import { editCar } from '../redux/actions/carsActions';
import globalVar from '../globalVar';
import emailjs from '@emailjs/browser';
import { approveRide, counterRide, declineRide, getAllRides } from '../redux/actions/rideActions';
import { Datepicker } from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

function Admin() {
    const { cars } = useSelector(state => state.carsReducer)
    const { bookings } = useSelector(state => state.bookingsReducer);
    const { loading } = useSelector(state => state.alertsReducer)
    const { users } = useSelector(state => state.usersReducer);
    const { customers } = useSelector(state => state.customersReducer);
    const { rides } = useSelector(state => state.ridesReducer);
    const [totalCars, settotalCars] = useState([])
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem("user"));
    const { Option } = Select;
    const [car, setCar] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [validDate, setvalidDate] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);

    function msToTime(ms) {
        let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (seconds < 60) return seconds + " Sec";
        else if (minutes < 60) return minutes + " Min";
        else if (hours < 24) return hours + " Hrs";
        else return days + " Days"
    }

    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const today = new Date();
    var count = 0;
    var count2 = 0;
    var count3 = 0;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    function selectTimeSlots(values) {
        console.log(values.value)
    }

    function validDates(values) {
        if (values === null) {
            document.getElementsByClassName("btm1").style.visibility = "hidden";
            message.error(
                "Please enter valid dates."
            )
        }
    }

    const options = [
        { value: 'system_admin', label: 'System Admin' },
        { value: 'user', label: 'User' },
        { value: 'driver', label: 'Driver' },
        { value: 'host', label: 'Host' },
    ]

    function getLabel(p, o) {
        for (var i = 0; i < o.length; i++) {
            if (p === o[i].value) {
                //console.log(o[i].label);
                return (o[i].label)
            }
        }
    }

    useEffect(() => {
        dispatch(getAllCars())
        dispatch(getAllBookings())
        dispatch(getAllUsers())
        dispatch(getAllCustomers())
        dispatch(getAllRides())
    }, [])

    useEffect(() => {

        settotalCars(cars)

    }, [cars])

    function onFinish(values) {
        users.filter(o => o._id === values._id).map((user) => {
            console.log(user)
            if (values.dob)
                user.dob = values.dob
            if (values.email)
                user.email = values.email
            if (values.name)
                user.name = values.name
            if (values.phone)
                user.phone = values.phone
            if (values.role) {
                user.role = values.role
            }
            dispatch(editUser(user))
        })
        console.log(values);
    };
    function onAdd(values) {
        values.password = "12345"
        values.dob = today
        dispatch(addUser(values))
        console.log(values);
        setIsModalOpen(false);
    };

    function onUNAV(values) {

        setCar(cars.find(o => o._id === values._id));
        car.bookedTimeSlots.push({
            from,
            to
        });
        values = car;
        dispatch(editCar(values));
        setIsModalOpen2(false);
        //console.log(values)
    };

    const [mode, setMode] = useState('top');
    const handleModeChange = (e) => {
        setMode(e.target.value);
    };



    return (
        <DefaultLayout>
            <div className='container mt-2 textColor'>

                {loading === true && (<Spinner />)}

                <Tabs
                    defaultActiveKey="1"
                    tabPosition={mode}
                    items={[
                        {
                            label: 'Bookings',
                            key: '1',
                            children:
                                <>
                                    <div className='mt-3'>
                                        <div className='row p-3'>
                                            <div className='col-md-12'>
                                                <h3 className='pt-3'>Current Trips</h3>
                                                <Divider />
                                                {bookings.map((booking) => {
                                                    const from = new Date(booking.bookedTimeSlots.from);
                                                    const to = new Date(booking.bookedTimeSlots.to);
                                                    if (today > from && today < to) {
                                                        count++;
                                                        return <div className='row bxs p-3 mb-4' >
                                                            <div className='col-md-4'>
                                                                <img src={booking.image} className='prof-img img-fluid' />
                                                            </div>
                                                            <div className='col-md-8'>
                                                                <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                                <p style={{ color: "dodgerblue" }}><b>{users.find(o => o._id === booking.user)?.name}</b></p>
                                                                <p>Total Days: <b>{booking.totalDays}</b></p>
                                                                <p>Allowed Miles: <b>{booking.mileage}</b></p>
                                                                <p>Booking Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                                {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                                <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                                <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                                <button className='btm1'>
                                                                    <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>View Details</Link>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                })}
                                                {count === 0 ? <p className='d-flex justify-content-center pb-3'>No Current Trips</p> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <div className='row p-3'>
                                            <div className='col-md-12'>
                                                <h3 className='pt-2'>Upcoming Trips</h3>
                                                <Divider />
                                                {bookings.map((booking) => {
                                                    const from = new Date(booking.bookedTimeSlots.from);
                                                    if (today < from) {
                                                        count2++;
                                                        return <div className='row bxs p-3 mb-4' >
                                                        <div className='col-md-4'>
                                                            <img src={booking.image} className='prof-img img-fluid' />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                            <p style={{ color: "dodgerblue" }}><b>{users.find(o => o._id === booking.user)?.name}</b></p>
                                                            <p>Total Days: <b>{booking.totalDays}</b></p>
                                                            <p>Allowed Miles: <b>{booking.mileage}</b></p>
                                                            <p>Booking Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                            {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                            <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <button className='btm1'>
                                                                <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>View Details</Link>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }
                                                })}
                                                {count2 === 0 ? <p className='d-flex justify-content-center pb-3'>No Upcoming Trips</p> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </>,
                        },
                        {
                            label: 'Rides',
                            key: '2',
                            children: <>
                                <div>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-1'>Pending Rides</h3>
                                            <Divider />
                                            {rides.map((ride) => {
                                                count3++;
                                                //console.log(count3)
                                                if (ride.pending && !ride.approved && !ride.declined && (ride.counterOffer === 0)) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Approval</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <button className='btm' style={{ color: "green", borderColor: "green", cursor: "pointer" }} onClick={() => {
                                                                dispatch(approveRide(ride))
                                                                emailjs.send(globalVar.Gmail_SRV, globalVar.Booked_Ride, {
                                                                    to_name: ride.user_name,
                                                                    email: ride.email,
                                                                    message: `${ride.date}\n${ride.from} - ${ride.to}\n${(ride.passengers > 1) ? ("Passengers: " + ride.passenger) : ""}\nYour Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.offer)}\nCounter Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.counterOffer)}`,
                                                                }, globalVar.GMail_Key).then(function (res) {
                                                                    console.log("Email Sent " + res.status)
                                                                });
                                                            }}>Approve</button>
                                                            <button className='btm1' onClick={() => {
                                                                setIsModalOpen3(true)
                                                            }}>Counter</button>
                                                            <Modal title="Counter Offer" open={isModalOpen3} onCancel={() => { setIsModalOpen3(false) }} footer={[
                                                                <Button form="counterForm" key="submit" htmlType="submit">
                                                                    Submit
                                                                </Button>
                                                            ]}>
                                                                <Form id='counterForm' onFinish={(values) => {
                                                                    ride.counterOffer = Number(values.counteroffer)
                                                                    console.log(ride);
                                                                    dispatch(counterRide(ride));
                                                                    setIsModalOpen3(false);
                                                                    emailjs.send(globalVar.Gmail_SRV, globalVar.Counter_Offer, {
                                                                        to_name: ride.user_name,
                                                                        email: ride.email,
                                                                        message: `${ride.date}\n${ride.from} - ${ride.to}\n${(ride.passengers > 1) ? ("Passengers: " + ride.passenger) : ""}\nYour Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.offer)}\nCounter Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.counterOffer)}`,
                                                                    }, globalVar.GMail_Key).then(function (res) {
                                                                        console.log("Email Sent " + res.status)
                                                                    });
                                                                }}>
                                                                    <Form.Item name="counteroffer" style={{ marginBottom: "3px", width: "100%" }} rules={[{ required: true, }]}>
                                                                        <input placeholder='Enter Your Counter Offer' type='number' style={{ width: "100%" }} />
                                                                    </Form.Item>
                                                                </Form>
                                                            </Modal>
                                                            <button id='button' className='btm2' onClick={() => {
                                                                dispatch(declineRide(ride))
                                                            }}>Decline</button>
                                                        </div>
                                                    </div>
                                                }
                                                else if (ride.pending && !ride.approved && !ride.declined && (ride.counterOffer !== 0)) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Acceptance</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                        <p><b style={{ color: "dodgerblue" }}>Counter Offer</b>: ${(ride.counterOffer).toFixed(2)}</p>
                                                        <button className='btm1' onClick={() => {
                                                            emailjs.send(globalVar.Gmail_SRV, globalVar.Counter_Offer, {
                                                                to_name: ride.user_name,
                                                                email: ride.email,
                                                                message: `${ride.date}\n${ride.from} - ${ride.to}\n${(ride.passengers > 1) ? ("Passengers: " + ride.passenger) : ""}\nYour Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.offer)}\nCounter Offer: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.counterOffer)}`,
                                                            }, globalVar.GMail_Key).then(function (res) {
                                                                console.log("Email Sent " + res.status)
                                                                message.success("Reminder Sent");
                                                            });
                                                        }}>Reminder</button>
                                                    </div>
                                                }
                                                else if (ride.approved && !ride.paid && !ride.declined) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Payment</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                        <button className='btm1' onClick={() => {
                                                            const dinero = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.total)
                                                            emailjs.send(globalVar.Gmail_SRV, globalVar.Pay_Reminder, {
                                                                to_name: ride.user_name,
                                                                email: ride.email,
                                                                message: `${ride.date}\n${ride.from} - ${ride.to}\n${(ride.passengers > 1) ? ("Passengers: " + ride.passenger) : ""}\n${dinero}`,
                                                            }, globalVar.GMail_Key).then(function (res) {
                                                                console.log("Email Sent " + res.status)
                                                                message.success("Reminder Sent");
                                                            });
                                                        }}>Pay Reminder</button>
                                                    </div>
                                                }
                                                else {
                                                    count3--;
                                                }
                                            })}
                                            {count3 === 0 ? <p className='d-flex justify-content-center pb-3'>No Pending Rides</p> : ""}
                                        </div>
                                    </div>
                                </div>
                            </>
                        },
                        {
                            label: 'Parking',
                            key: '3',
                            children: "Coming Soon"
                        },
                        {
                            label: 'Vehicles',
                            key: '4',
                            children:
                                <>
                                    <div className='d-flex justify-content-center mt-2 pe-4'>
                                        <a href='/add' className='logbutton m-2' style={{ textDecoration: "none" }}>Add New Vehicle</a>
                                        <a onClick={showModal2} className='logbutton m-2' style={{ textDecoration: "none" }}>Add Unavailability</a>
                                    </div>
                                    <Modal title="Add Unavailability" open={isModalOpen2} onCancel={handleCancel2} footer={[
                                        <Button form="UNAVmyForm" key="submit" htmlType="submit">
                                            Submit
                                        </Button>
                                    ]}>
                                        <Form id='UNAVmyForm' layout='horizontal' onFinish={onUNAV}>
                                            <Form.Item name="_id" label="Vehicle">
                                                <Select onChange={(e) => setCar(e)}>
                                                    {totalCars.map((car) => {
                                                        return (
                                                            <Option value={car._id}>{car.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Datepicker name="dates" className="" inputProps={{
                                                placeholder: 'Select Unavailability Dates...'
                                            }} responsive={{
                                                small: {
                                                    display: 'center',
                                                    pages: 1,
                                                    touchUi: true,
                                                },
                                            }} themeVariant='light' min={today} touchUi={true} theme="ios" controls={['calendar', 'time']} select="range" onChange={(values) => {
                                                setFrom(moment(values.value[0]).format("MM DD yyyy h:mm"));
                                                setTo(moment(values.value[1]).format("MM DD yyyy h:mm"));
                                            }} />
                                        </Form>
                                    </Modal>
                                    <div className="container">
                                        <div className="row">
                                            {totalCars.map((car) => {
                                                return (<div className="col d-flex justify-content-center m-2">
                                                    <div className='car p-2 bxs2'>
                                                        <img className='carimg img-fluid' src={car.images[0].url} />
                                                        <div className='car-content d-flex align-items-center justify-content-between'>
                                                            <div>
                                                                <div><p>{car.name}</p></div>
                                                                <div><p>${car.rentPerDay} / Day</p></div>
                                                            </div>
                                                            <div className="me-4">
                                                                <Link to={`/edit/${car._id}`}><EditOutlined className='me-3' style={{ color: "black" }} /></Link>
                                                                <Popconfirm
                                                                    title="Are you sure to delete this car?"
                                                                    onConfirm={() => {
                                                                        dispatch(deleteCar({ carid: car._id }))
                                                                    }}
                                                                    okText="Yes"
                                                                    cancelText="No" >
                                                                    <DeleteOutlined />
                                                                </Popconfirm>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </>
                        },
                        {
                            label: 'Parking Leads',
                            key: '5',
                            children:
                                <div className='mt-3'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-2'>Parking Leads</h3>
                                            <Divider />
                                            {customers.map((customer) => {
                                                if (customer.reason !== "main")
                                                    return <Row className="bxs mt-3 p-3 text-right flex align-items-center">
                                                        <Col lg={6} sm={24}>
                                                            <p><b></b></p>
                                                            <p>Name: <b>{customer.name}</b></p>
                                                            <p>Email : <b>{customer.email}</b></p>
                                                            {customer.phone ? <p>Phone : <b>{customer.phone}</b></p> : ""}
                                                            <p>Reason : <b>{customer.reason}</b></p>
                                                            <p>Comments : <b>{customer.comment}</b></p>
                                                        </Col>
                                                    </Row>
                                            })}
                                        </div>
                                    </div>
                                </div>
                        },
                        {
                            label: 'Subscribers',
                            key: '6',
                            children:
                                <div className='mt-3'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-2'>Subscribers</h3>
                                            <Divider />
                                            {customers.map((customer) => {
                                                if (customer.reason === "main")
                                                    return <Row className="bxs mt-3 p-3 text-right flex align-items-center">
                                                        <Col lg={6} sm={24}>
                                                            <p><b></b></p>
                                                            <p>Name: <b>{customer.name}</b></p>
                                                            <p>Email : <b>{customer.email}</b></p>
                                                            {customer.phone ? <p>Phone : <b>{customer.phone}</b></p> : ""}
                                                            <p>Reason : <b>{customer.reason}</b></p>
                                                            <p>Comments : <b>{customer.comment}</b></p>
                                                        </Col>
                                                    </Row>
                                            })}
                                        </div>
                                    </div>
                                </div>
                        },
                        {
                            label: 'Users',
                            key: '7',
                            children:
                                <>
                                    <div className='d-flex justify-content-end mt-2 pe-4'>
                                        <a onClick={showModal} className='logbutton' style={{ textDecoration: "none" }}>Add New User</a>
                                    </div>
                                    {users.map((user) => {
                                        return <div className="bxs mt-3 p-1 flex justify-content-center">
                                            <Form initialValues={user} layout='vertical' onFinish={onFinish} className="p-4">
                                                <Form.Item name='name' label="User Name" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name='email' label="User Email" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name='dob' label="User Date of Birth" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name='phone' label="User Phone Number">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name='role' label="User Role">
                                                    <Select className='d-flex justify-content-start' options={options} style={{ width: 150, }} />
                                                </Form.Item>
                                                <Form.Item name='_id' label="User ID">
                                                    <Input disabled />
                                                </Form.Item>
                                                <div className='d-flex justify-content-end'>
                                                    <button className='btm1 me-2'>Edit User</button>
                                                    <Popconfirm
                                                        title="Are you sure to delete this user?"
                                                        onConfirm={() => {
                                                            dispatch(deleteUser({ userid: user._id }))
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No" >
                                                        <button className='btm2 me-2'>Delete User</button>
                                                    </Popconfirm>
                                                    <button className='btm' onClick={() => {
                                                        const username = user?.name?.split(" ");
                                                        emailjs.send(globalVar.Gmail_SRV, globalVar.PW_Reset, {
                                                            to_name:
                                                                username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1),
                                                            email: user.email,
                                                            message: user._id,
                                                        },
                                                            globalVar.GMail_Key
                                                        )
                                                            .then(function (res) {
                                                                console.log("Email Sent " + res.status);
                                                            });
                                                        //window.location.href = "/login";
                                                        message.success(
                                                            "Password Reset Email Sent"
                                                        );
                                                    }}>Send Reset Password</button>
                                                </div>
                                            </Form>
                                        </div>
                                    })}
                                    <Modal title="Add New User" open={isModalOpen} footer={null} onCancel={handleCancel}>
                                        <Form layout='vertical' className="p-4" onFinish={onAdd}>
                                            <Form.Item name='name' label="User Name" rules={[{ required: true }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name='email' label="User Email" rules={[{ required: true }]}>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name='phone' label="User Phone Number">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name='role' label="User Role">
                                                <Select className='d-flex justify-content-start' options={options} style={{ width: 150, }} />
                                            </Form.Item>
                                            <div className='d-flex justify-content-end'>
                                                <button className='btm1'>Add User</button>
                                            </div>
                                        </Form>
                                    </Modal>
                                </>
                        },
                        {
                            label: 'Past Rentals',
                            key: '8',
                            children:
                                <>
                                    {bookings.reverse().map((booking) => {
                                        //console.log(booking)
                                        return <div className='row bxs m-3 p-2'>
                                            <div className='col-md-4'>
                                                <img src={booking.image} height='150' className='p-2' />
                                            </div>
                                            <div className='col-md-8'>
                                                <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                <p>Trip Booking Date : <b>{moment(booking.createdAt).format('MMM Do yyyy')}</b></p>
                                                {booking.deliveryRequired ? <p>Delivery: <b style={{ overflowY: "scroll" }}>{booking.location}</b></p> : <p style={{ overflowY: "scroll" }}>Pick Up: <b>{booking.location}</b></p>}
                                                <p>From : <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm a')}</b></p>
                                                <p>To : <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm a')}</b></p>
                                                <p>Total : <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                {!booking.completed ?
                                                    <button className='btm2'>
                                                        <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none", color: "red" }}>Incomplete</Link>
                                                    </button>
                                                    : <button className='btm1'>
                                                        <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>View Details</Link>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </>
                        },
                        {
                            label: 'Completed Rides',
                            key: '9',
                            children:
                                <>
                                    {rides.map((ride) => {
                                        //var endTime = new Date(ride.finished);
                                        //var startTime = moment(ride.start, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm a');
                                        if (ride.completed)
                                            return (<div className='bxs p-3'>
                                                <h5>{ride.user_name}'s Ride</h5>
                                                <Divider style={{ backgroundColor: "dodgerblue" }} />
                                                <p><b>Date</b>: {ride.date}</p>
                                                <p><b>Origin</b>: {ride.from}</p>
                                                <p><b>Destination</b>: {ride.to}</p>
                                                <p><b>Distance</b>: {ride.results.distance}</p>
                                                <p><b>Estimated Duration</b>: {ride.results.duration}</p>
                                                <p><b>Time Length</b>: {msToTime((new Date(ride.finished).getTime() - new Date(ride.start).getTime()) * -1)}</p>
                                                <p><b>Total</b>: ${ride.total}</p>
                                            </div>
                                            )
                                    })}
                                </>
                        },
                    ]}
                />

            </div>
        </DefaultLayout >
    )
}

export default Admin


{/* <Row className="bxs mt-3 p-3 text-right flex align-items-center">
                                                            <Col lg={6} sm={24}>
                                                                <img src={booking.image} height='150' className='p-2' />
                                                            </Col>
                                                            <Col lg={6} sm={24}>
                                                                <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                                <p style={{ color: "dodgerblue" }}><b>{users.find(o => o._id === booking.user)?.name}</b></p>
                                                                <p>Total Days: <b>{booking.totalDays}</b></p>
                                                            </Col>
                                                            <Col lg={12} sm={24}>
                                                                <p>Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                                {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                                <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                                <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            </Col>
                                                            <Col lg={24} sm={36}>
                                                                <button className='btm1'>
                                                                    <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>View Details</Link>
                                                                </button>
                                                            </Col>
                                                        </Row> 
                                                    
                                                    
                                                    
                                                    <Row className="bxs mt-3 p-3 text-right flex align-items-center">
                                                            <Col lg={6} sm={24}>
                                                                <img src={booking.image} height='150' className='p-2' />
                                                            </Col>
                                                            <Col lg={6} sm={24}>
                                                                <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                                <p style={{ color: "dodgerblue" }}><b>{users.find(o => o._id === booking.user)?.name}</b></p>
                                                                <p>Total Days: <b>{booking.totalDays}</b></p>
                                                                <p>Total Miles: <b>{booking.mileage}</b></p>
                                                                <p>Rate: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.carRate)}</b>/ Day</p>
                                                                <p>Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                            </Col>
                                                            <Col lg={12} sm={24}>
                                                                <div className='d-flex flex-row'><p className='idTag'>Transaction ID:</p><p style={{ overflowY: "scroll" }}><b>{booking.transactionID}</b></p></div>
                                                                {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                                <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
                                                                <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
                                                                <p>Trip Booking Date: <b>{moment(booking.createdAt).format('MMM Do yyyy')}</b></p>
                                                            </Col>
                                                        </Row>
                                                    
                                                    
                                                    
                                                    
                                                    
                                                    */}