import React, { useRef, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { useJsApiLoader, GoogleMap, Marker, Autocomplete, DirectionsRenderer, } from "@react-google-maps/api"
import { useEffect } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import globalVar from '../globalVar';
import { Datepicker } from "@mobiscroll/react";
import moment from 'moment';
import emailjs from '@emailjs/browser';
import { useDispatch } from 'react-redux';
import { requestRide } from '../redux/actions/rideActions';

function BookRide() {
  const dispatch = useDispatch();
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [pick, setpick] = useState();
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [visible, setVisible] = useState(false)
  const [rideDate, setRideDate] = useState()
  const [obj, setObj] = useState()
  const today = new Date().setHours(0, 0, 0, 0);
  const user = JSON.parse(localStorage.getItem("user"));
  const center = { lat: 30.2671530, lng: -97.7430608 }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: globalVar.GMap_Key,
    libraries: ['places']
  })

  useEffect(() => {
    document.title = "Get a Ride, Get a Driver in Austin | Ride to Austin Bergstrom Airport | Rora"
  },[])

  const handlePick = (value) => {
    setpick(value)
  }

  console.log(globalVar.GMap_Key)

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  async function calculateRoute() {
    if (originRef.current.value === '' || destinationRef.current.value === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
    setVisible(true)
  }

  function selectDate(value) {
    setRideDate(value)
  }

  function onFinsh(values) {
    values.user = JSON.parse(localStorage.getItem('user'))._id;
    values.user_name = JSON.parse(localStorage.getItem('user')).name;
    values.from = directionsResponse.request.origin.query;
    values.to = directionsResponse.request.destination.query;
    values.approved = false;
    values.email = JSON.parse(localStorage.getItem('user')).email;
    values.accepted = false;
    values.pending = true;
    values.paid = false;
    values.completed = false;
    values.message = "";
    values.counterOffer = 0;
    values.total = 0;
    values.type = 'ride';
    values.passengers = Number(values.passengers);
    values.date = moment(rideDate.value).format("MMM DD YYYY h:mm A");
    values.offer = (Math.ceil(values.offer * 100) / 100);
    values.results = {
      distance: distance,
      duration: duration,
    };
    dispatch(requestRide(values));
    const dinero = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(values.offer)

    emailjs.send(globalVar.Gmail_SRV, globalVar.Ride_Request, {
      to_name: values.user_name,
      email: values.email,
      message: `${values.date}\n${values.from} - ${values.to}\nDistance: ${values.results.distance}\nDuration: ${values.results.duration}\n${(Number(values.passengers) > 1) ? ("Passengers: " + Number(values.passengers)) : ""}\n${dinero}`,
    }, globalVar.GMail_Key).then(function (res) {
      console.log("Email Sent " + res.status)
    });

    emailjs.send(globalVar.Gmail_SRV, globalVar.Admin_RideRequest, {
      from_name: values.user_name,
      message: `${values.date}\n${values.from} - ${values.to}\nDistance: ${values.results.distance}\nDuration: ${values.results.duration}\n${(Number(values.passengers) > 1) ? ("Passengers: " + Number(values.passengers)) : ""}\n${dinero}`,
    }, globalVar.GMail_Key).then(function (res) {
      console.log("Email Sent " + res.status)
    });
  }
  var distNum = (Math.ceil(parseFloat(distance)));

  return isLoaded ? (
    <DefaultLayout>
      <div className='container'>
        <div className="bxs p-3 textColor">
          <h3>Book Your Ride</h3>
          <div className='row'>
            <div className='col-md-4 p-2'>
              <GoogleMap center={center} zoom={10} options={{ mapTypeControl: false, streetViewControl: false }} mapContainerStyle={{ width: '100%', height: '370px' }} onLoad={map => setMap(map)}>
                {directionsResponse && (
                  <DirectionsRenderer directions={directionsResponse} />)}
              </GoogleMap>
            </div>
            <div className='col-md-8'>
              <div>
                <p>Ride Type</p>
                <div className='featBorder p-3'>
                  <Select className='m-2' style={{ width: '100%' }} options={[
                    { value: 'ride', label: "Single Ride" },
                    { value: 'rideservice', label: "Ride Service (Multi-Day or Multi-Stop Ride)" },
                  ]} onChange={handlePick} />
                </div>
              </div>
              {pick === undefined ? "" : pick === "ride" ? <><div>
                <p>Pickup</p>
                <div className='featBorder p-3'>
                  <Autocomplete>
                    <input type='text' style={{ width: "100%" }} ref={originRef} disabled={!user} />
                  </Autocomplete>
                </div>
              </div>
                <div>
                  <p>Drop Off</p>
                  <div className='featBorder p-3'>
                    <Autocomplete>
                      <input type='text' style={{ width: "100%" }} ref={destinationRef} disabled={!user} />
                    </Autocomplete>
                  </div>
                </div>
                <div>
                  <p>Date &amp; Time</p>
                  <div className='featBorder p-3'>
                    <Datepicker className="" inputProps={{
                      placeholder: 'Please Select Ride Time...'
                    }} responsive={{
                      small: {
                        display: 'center',
                        pages: 1,
                        touchUi: true,
                      },
                    }} disabled={!user} themeVariant='light' min={today} touchUi={true} theme="ios" controls={['datetime']} onChange={selectDate} />
                  </div>
                </div>
                {user ? (!visible ? <button className='btm1 mt-3' onClick={calculateRoute}>Get Info</button> :
                  <>
                    <div className='d-flex justify-content-between mt-3'>
                      <div><b>Distance</b>: {distance}</div>
                      <div><b>Duration</b>: {duration}</div>
                    </div>
                    <Form onFinish={onFinsh}>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <p><b>Passengers</b></p>
                          <div className='featBorder p-3 d-flex align-items-center'>
                            <Form.Item name="passengers" style={{ marginBottom: "3px", width: "100%" }} rules={[{ required: true, }]}>
                              <input placeholder='Max 4' type='number' style={{ width: "100%" }} max="4" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <p><b>Cost</b></p>
                          <div className='featBorder p-3 d-flex inline align-items-center'>
                            $&nbsp;
                            <Form.Item name="offer" style={{ marginBottom: "3px", width: "100%" }} rules={[{ required: true, }]}>
                              <input placeholder='Enter Your Cost Offer' type='number' style={{ width: "100%" }} min={distNum} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <button className='btm1 mt-3'>Submit Offer</button>
                    </Form>
                  </>)
                  : <button className='btm1 mt-3' onClick={() => {
                    window.location.href = "/login";
                  }}>Login/Sign Up to Book</button>
                } </> : <div>
                <p><b>Instructions</b></p>
                <div className='featBorder p-3'>
                  <p>Please contact us via <a href="tel: 7373145981">Phone</a>, <a href="sms: 7373145981&body=Ride Service Request: ">Text</a>, or <a href="mailto: admin@roraoftexas.com?subject=Ride Service Request">Email</a> with requested ride location(s), date &amp; time, and budget.</p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  ) : " Error"
};

export default BookRide