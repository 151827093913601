import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/Home.js";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Booking from "./pages/Booking";
import AddCar from "./pages/AddCar";
import Admin from "./pages/Admin";
import EditCar from "./pages/EditCar";
import Profile from "./pages/Profile";
import About from "./pages/About";
import Verify from "./pages/Verify";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import SuccessBooking from "./pages/Success";
import {
  ProtectedAuthRoute,
  ProtectedRoute,
} from "./routes/protectedAuthRoutes";
import Rent from "./pages/Rent";
import Park from "./pages/Park";
import BookRide from "./pages/BookRide";
import Pay from "./pages/Pay";
import FAQ from "./pages/FAQ";
import Driver from "./pages/Driver";
import Ride from "./pages/Ride";
import Reservations from "./pages/Reservations";
import useWebSocket from 'react-use-websocket';
import ReactGA from "react-ga4";
import Advertise from "./pages/Advertise";

//const WS_URL = "ws://127.0.0.1:4000/socket"
const WS_URL = "wss://api.roraoftexas.com/socket"

function App() {
  useWebSocket(WS_URL, {
    onOpen:()=>{
      console.log('Websocket connected')
    }, onError: (ev)=>{
      console.log(ev)
    }
  })

  const TRACKING_ID = 'G-6QZQ492S09';
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: document.location.pathname });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              //<ProtectedRoute>
                <Home />
             //</ProtectedRoute>
            }
          />
          <Route
            exact
            path="/rent"
            element={
              //<ProtectedRoute>
                <Rent />
             //</ProtectedRoute>
            }
          />
          <Route
            exact
            path="/faq"
            element={
              //<ProtectedRoute>
                <FAQ />
             //</ProtectedRoute>
            }
          />
          <Route
            exact
            path="/storage"
            element={
              //<ProtectedRoute>
                <Park />
             //</ProtectedRoute>
            }
          />
          <Route
            exact
            path="/bookride"
            element={
              //<ProtectedRoute>
                <BookRide />
             //</ProtectedRoute>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <ProtectedAuthRoute>
                <Login />
              </ProtectedAuthRoute>
            }
          />
          <Route
            path="/pay/:sessionid"
            element={
              <ProtectedRoute>
                <Pay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <ProtectedAuthRoute>
                <Register />
              </ProtectedAuthRoute>
            }
          />
          <Route
            path="/booking/:vehicleid"
            element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ride/:rideid"
            element={
              <ProtectedRoute>
                <Ride />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mystuff"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add"
            element={
              <ProtectedRoute>
                <AddCar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/driver"
            element={
              <ProtectedRoute>
                <Driver />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route
            path="/edit/:carid"
            element={
              <ProtectedRoute>
                <EditCar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verify"
            element={
              // <ProtectedRoute>
              <Verify />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/ads"
            element={
              // <ProtectedRoute>
              <Advertise />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/success/:sessionid"
            element={
              // <ProtectedRoute>
              <SuccessBooking />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/reservation/:bookingid"
            element={
               <ProtectedRoute>
              <Reservations />
               </ProtectedRoute>
            }
          />
          <Route
            path="/reset/:userid"
            element={
              // <ProtectedRoute>
                <PasswordReset />
              // </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// export function ProtectedRoute(props) {
//   if (localStorage.getItem("user")) {
//     return <Route {...props} />;
//   } else {
//     return <redirect to="/login" />;
//   }
// }
