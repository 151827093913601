import React, { useEffect, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { getAllBookings } from '../redux/actions/bookingActions';
import { deleteCar, getAllCars } from '../redux/actions/carsActions';
import { DatePicker, Col, Row, Divider, Form, Input, message, Popconfirm, Select, Modal, Switch, Button, Radio, Tabs } from 'antd';
import moment from 'moment';
import Spinner from '../components/Spinner';
import { editUser } from '../redux/actions/userActions';
import { editCar } from '../redux/actions/carsActions';
import { getAllUsers } from '../redux/actions/userActions';
import Edit from '../assests/edit.png';
import { acceptRide, approveRide, getAllRides, deleteRide } from '../redux/actions/rideActions';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Option } from 'antd/es/mentions';
import { Datepicker } from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { getOnlineDrivers } from '../redux/actions/driverActions';


function UserBookings() {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.alertsReducer);
    const { bookings } = useSelector(state => state.bookingsReducer);
    const { cars } = useSelector(state => state.carsReducer);
    const { users } = useSelector(state => state.usersReducer);
    const { drivers } = useSelector(state => state.driverReducer);
    const { rides } = useSelector(state => state.ridesReducer);
    const [date, setDate] = useState("");
    const [historyFilter, setHistoryFilter] = useState("rental");
    const [sessionID, setSessionID] = useState("");
    const user = JSON.parse(localStorage.getItem("user"));
    const today = new Date();
    var count = 0;
    var count2 = 0;
    var count3 = 0;
    var count4 = 0;
    var count5 = 0;
    var count6 = 0;
    localStorage.setItem("session", sessionID);
    var newDate = new Date(user.dob);
    user.dob = moment(newDate).format("MM/DD/YYYY");
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [totalCars, settotalCars] = useState([]);
    const [car, setCar] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    var earnings = 0;

    //console.log(user)

    function makeSessionid(length) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        dispatch(getAllBookings())
        dispatch(getAllCars())
        dispatch(getAllRides())
        dispatch(getOnlineDrivers())
        dispatch(getAllUsers())
        setSessionID(makeSessionid(7));
    }, [])

    function onUNAV(values) {
        setCar(cars.find(o => o._id === values._id));
        car.bookedTimeSlots.push({
            from,
            to
        });
        values = car;
        dispatch(editCar(values));
        setIsModalOpen2(false);
    };

    function onFinish(values) {
        values.role = user.role;
        values.code = user.code;
        values.verified = user.verified;
        values._id = user._id;
        dispatch(editUser(values))
    }

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    return (
        <DefaultLayout>
            {loading === true && (<Spinner />)}
            <div className='container textColor'>
                <div className='row'>
                    <div className="col-md-12">
                        <ul className='nav nav-tabs d-flex' role="tablist">
                            <li className='nav-item'>
                                <a className='nav-link active' data-bs-toggle="tab" data-bs-target="#nav-current"
                                    type="button" role="tab" aria-controls="nav-current" aria-selected="true">Rentals</a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-link' data-bs-toggle="tab" data-bs-target="#nav-rides"
                                    type="button" role="tab" aria-controls="nav-rides" aria-selected="false">Rides</a>
                            </li>
                            {user?.role === "host" ?
                                <li className='nav-item text-nowrap'>
                                    <a className='nav-link' data-bs-toggle="tab" data-bs-target="#nav-host"
                                        type="button" role="tab" aria-controls="nav-host" aria-selected="false">My Vehicles</a>
                                </li>
                                : ""}
                            <li className='nav-item text-nowrap'>
                                <a className='nav-link' data-bs-toggle="tab" data-bs-target="#nav-info"
                                    type="button" role="tab" aria-controls="nav-info" aria-selected="false">My Info</a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-link' data-bs-toggle="tab" data-bs-target="#nav-bookings"
                                    type="button" role="tab" aria-controls="nav-bookings" aria-selected="true">History</a>
                            </li>
                        </ul>
                        <div className='tab-content'>
                            <div className='tab-pane fade show active' role="tabpanel" aria-labelledby="nav-current-tab" id='nav-current'>
                                <div className='mt-3'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-3'>Current Trips</h3>
                                            <Divider />
                                            {bookings.filter(o => o.user === user._id).map((booking) => {
                                                const from = moment(booking.bookedTimeSlots.from, "MM DD YYYY HH:mm").toDate()
                                                const to = moment(booking.bookedTimeSlots.to, "MM DD YYYY HH:mm").toDate()
                                                if (today > from && today < to) {
                                                    count++;
                                                    return <div className='row bxs p-3 mb-4' >
                                                        <div className='col-md-4'>
                                                            <img src={booking.image} className='prof-img img-fluid' />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <div className='d-flex justify-content-between'>
                                                                <p style={{ color: "dodgerblue" }}><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                                <Popconfirm
                                                                    title="Do you need to Edit or Cancel this Trip?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    onConfirm={() => {
                                                                        window.location = `mailto:admin@roraoftexas.com?subject=Trip ${booking.transactionID}`;
                                                                    }}>
                                                                    <img src={Edit} width="40" className='p-2' style={{ cursor: "pointer" }} />
                                                                </Popconfirm>
                                                            </div>
                                                            <p>Total Days: <b>{booking.totalDays}</b></p>
                                                            <p>Allowed Miles: <b>{booking.mileage}</b></p>
                                                            <p>Booking Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                            {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                            <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <button className='btm1'>
                                                                <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>More Info</Link>
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                            {count === 0 ? <p className='d-flex justify-content-center pb-3'>No Current Trips</p> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-2'>Upcoming Trips</h3>
                                            <Divider />
                                            {bookings.filter(o => o.user === user._id).map((booking) => {
                                                const from = moment(booking.bookedTimeSlots.from, "MM DD YYYY HH:mm").toDate()
                                                if (today < from) {
                                                    count2++;
                                                    return <div className='row bxs p-3 mb-4' >
                                                    <div className='col-md-4'>
                                                        <img src={booking.image} className='prof-img img-fluid' />
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <div className='d-flex justify-content-between'>
                                                            <p style={{ color: "dodgerblue" }}><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                            <Popconfirm
                                                                title="Do you need to Edit or Cancel this Trip?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                                onConfirm={() => {
                                                                    window.location = `mailto:admin@roraoftexas.com?subject=Trip ${booking.transactionID}`;
                                                                }}>
                                                                <img src={Edit} width="40" className='p-2' style={{ cursor: "pointer" }} />
                                                            </Popconfirm>
                                                        </div>
                                                        <p>Total Days: <b>{booking.totalDays}</b></p>
                                                        <p>Allowed Miles: <b>{booking.mileage}</b></p>
                                                        <p>Booking Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>
                                                        {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                        <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                        <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('ddd, MMM Do yyyy, h:mm A')}</b></p>
                                                        <button className='btm1'>
                                                            <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>More Info</Link>
                                                        </button>
                                                    </div>
                                                </div>
                                                }
                                            })}
                                            {count2 === 0 ? <p className='d-flex justify-content-center pb-3'>No Upcoming Trips</p> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-pane fade' role="tabpanel" aria-labelledby="nav-rides-tab" id='nav-rides'>
                                <div className='mt-1'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-3'>Pending Rides</h3>
                                            <Divider />
                                            {rides.filter(o => o.user === user._id).map((ride) => {
                                                count4++;
                                                if (ride.pending && !ride.approved && !ride.declined && (ride.counterOffer === 0)) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Approval</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Your Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                    </div>
                                                }
                                                else if (ride.pending && (ride.counterOffer !== 0) && !ride.approved && !ride.declined) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Acceptance</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Your Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                        <p><b style={{ color: "dodgerblue" }}>Counter Offer</b>: ${(ride.counterOffer).toFixed(2)}</p>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <button className='btm1' onClick={() => {
                                                                dispatch(acceptRide(ride))
                                                            }}>Accept</button>
                                                            <button className='btm2'>Decline</button>
                                                        </div>
                                                    </div>
                                                }
                                                else if (ride.approved && !ride.paid && !ride.declined) {
                                                    return <div className='bxs p-3 mb-3'>
                                                        <h5>Pending Payment</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b style={{ color: "dodgerblue" }}>Subtotal</b>: ${ride.accepted ? ((ride.counterOffer).toFixed(2)) : ((ride.offer).toFixed(2))}</p>
                                                        <button className='btm1'>
                                                            <Link style={{ textDecoration: "none" }} to={`/pay/${sessionID}`} state={{ ride }}>Pay Now</Link>
                                                        </button>
                                                    </div>
                                                }
                                                else if (ride.declined) {
                                                    return <div className='pb-3'>
                                                        <h5 style={{ color: "red" }}>Declined Ride</h5>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.to}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Your Offer</b>: ${(ride.offer).toFixed(2)}</p>
                                                        <div className='d-flex inline justify-content-between align-items-center'>
                                                            <p style={{ color: "red" }}>No available drivers at this time.</p>
                                                            <DeleteOutlined onClick={() => {
                                                                dispatch(deleteRide(ride))
                                                                count4--;
                                                            }} />
                                                        </div>
                                                    </div>
                                                }
                                                else {
                                                    count4--;
                                                }
                                            })}
                                            {count4 === 0 ? <p className='d-flex justify-content-center pb-3'>No Pending Rides</p> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <div className='row p-3'>
                                        <div className='col-md-12'>
                                            <h3 className='pt-3'>Rides Enroute</h3>
                                            <Divider />
                                            {rides.filter(o => o.user === user._id).map((ride) => {
                                                count5++;
                                                if (ride.approved && ride.paid && !ride.completed) {
                                                    console.log(ride)
                                                    return <div className='bxs p-3 mb-3'>
                                                        <p><b>Date</b>: {ride.date}</p>
                                                        <p><b>Origin</b>: {ride.from}</p>
                                                        <p><b>Destination</b>: {ride.to}</p>
                                                        {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.passengers}</p> : ""}
                                                        <p><b>Distance</b>: {ride.results.distance}</p>
                                                        <p><b>Duration</b>: {ride.results.duration}</p>
                                                        <p><b>Total</b>: ${(ride.total).toFixed(2)}</p>
                                                        {ride.driver ? <Divider /> : ""}
                                                        <p><b style={{ color: "dodgerblue" }}>Driver</b>
                                                            : {ride.driver ? <>
                                                                <p><b>Name</b>: {users.find(o => o._id === ride.driver)?.name.split(" ")[0] + " " + users.find(o => o._id === ride.driver)?.name.split(" ")[1].charAt(0)}</p>
                                                                <p><b>Car</b>: {drivers.find(o => o.id === ride.driver)?.carName}</p>
                                                                <p><b>Plate</b>: {drivers.find(o => o.id === ride.driver)?.plate}</p>
                                                                <div className='d-flex justify-content-center mt-2'>
                                                                    <button className='btm1'><Link to={`/ride/${ride._id}`} style={{ textDecoration: "none" }}>View</Link></button>
                                                                </div>
                                                            </> : "Getting you a driver"}</p>
                                                    </div>
                                                }
                                                else {
                                                    count5--;
                                                }
                                            })}
                                            {count5 === 0 ? <p className='d-flex justify-content-center pb-3'>No Rides Enroute</p> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-pane fade' role="tabpanel" aria-labelledby="nav-bookings-tab" id='nav-bookings'>
                                <div className='p-2 d-flex'>
                                    <div className={(historyFilter === "rental") ? "pill-active me-4" : "pill me-4"} onClick={() => {
                                        setHistoryFilter("rental")
                                    }}>Rentals</div>
                                    <div className={(historyFilter !== "rental") ? "pill-active me-2" : "pill me-2"} onClick={() => {
                                        setHistoryFilter("rides")
                                    }}>Rides</div>
                                </div>
                                {historyFilter === "rental" ? <>
                                    {bookings.filter(o => o.user === user._id).reverse().map((booking) => {
                                        count3++;
                                        return <div className='row bxs m-3' >
                                            <div className='col-md-4'>
                                                <img src={booking.image} height='150' className='p-3' />
                                            </div>
                                            <div className='col-md-8 p-2'>
                                                <p><u><b>{cars.find((o) => o._id === booking.vehicle)?.name}</b></u></p>
                                                <p><b>From: </b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</p>
                                                <p><b>To: </b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</p>
                                                <p><b>Total: </b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</p>
                                                <p><b>Booking Date: </b>{moment(booking.createdAt).format('MMM Do yyyy')}</p>
                                                {booking.completed ? <button className="btm1">
                                                    <Link to={`/reservation/${booking._id}`} style={{ textDecoration: "none" }}>View Details</Link>
                                                </button> : <p className='d-flex justify-content-center' style={{ color: "red" }}>Post Trip Details Coming Soon</p>}
                                            </div>
                                        </div>
                                    })}
                                    {count3 === 0 ? <p className='d-flex justify-content-center p-3'>No Previous Rentals</p> : ""}
                                </> : <>
                                    {rides.filter(o => o.user === user._id).reverse().map((ride) => {
                                        count6++;
                                        return <div className='row bxs m-3'>
                                            <div className='col-md-8 p-3'>
                                                <p><b>Booking Date: </b>{moment(ride.createdAt).format('MMMM Do yyyy h:mm A')}</p>
                                                <p><b>From: </b>{ride.from}</p>
                                                <p><b>To: </b>{ride.to}</p>
                                                <p><b>Total: </b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ride.total)}</p>
                                            </div>
                                        </div>
                                    })}
                                    {count6 === 0 ? <p className='d-flex justify-content-center p-3'>No Previous Rides</p> : ""}
                                </>}
                            </div>
                            <div className='tab-pane fade' role="tabpanel" aria-labelledby="nav-info-tab" id='nav-info'>
                                <div className="bxs p-3 mt-3">
                                    <div className="row">
                                        <div className="col-md-12 flex justify-content-start textColor">
                                            {users.filter(o => o._id === user._id).map((onlUser) => {
                                                var userDOB = moment(onlUser.dob).format("MM/DD/yyyy");
                                                return <Form initialValues={onlUser} className='p-2' layout='vertical' onFinish={onFinish}>
                                                    <Form.Item name="name" label="Name">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name="dob" label="Date of Birth">
                                                        <DatePicker format="MM/DD/YYYY" defaultValue={moment(userDOB)} className='d-flex align-items-start textColor' />                                                </Form.Item>
                                                    <Form.Item name="email" label="Email">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name="phone" label="Phone Number">
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item name="password" label="Password">
                                                        <Input type="password" />
                                                    </Form.Item>
                                                    <div className='d-flex justify-content-end'>
                                                        <button className='btm1'>Update Info</button>
                                                    </div>
                                                </Form>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tab-pane fade' role="tabpanel" aria-labelledby="nav-host-tab" id='nav-host'>
                                <div className='d-flex justify-content-center mt-2 pe-4'>
                                    <a href='/add' className='logbutton m-2' style={{ textDecoration: "none" }}>Add New Vehicle</a>
                                    <a onClick={showModal2} className='logbutton m-2' style={{ textDecoration: "none" }}>Add Unavailability</a>
                                </div>
                                <Modal title="Add Unavailability" open={isModalOpen2} onCancel={handleCancel2} footer={[
                                    <Button form="UNAVmyForm" key="submit" htmlType="submit">
                                        Submit
                                    </Button>
                                ]}>
                                    <Form id='UNAVmyForm' layout='horizontal' onFinish={onUNAV}>
                                        <Form.Item name="_id" label="Vehicle">
                                            <Select onChange={(e) => setCar(e)}>
                                                {cars.filter((o) => o.owner === user._id).map((car) => {
                                                    return (
                                                        <Option value={car._id}>{car.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Datepicker name="dates" className="" inputProps={{
                                            placeholder: 'Select Unavailability Dates...'
                                        }} responsive={{
                                            small: {
                                                display: 'center',
                                                pages: 1,
                                                touchUi: true,
                                            },
                                        }} themeVariant='light' min={today} touchUi={true} theme="ios" controls={['calendar', 'time']} select="range" onChange={(values) => {
                                            console.log(values.value)
                                            setFrom(moment(values.value[0], "MM DD yyyy h:mm A").format("MMMM Do YYYY h:mm A"));
                                            setTo(moment(values.value[1]).format("MM DD yyyy h:mm A"));
                                        }} />
                                    </Form>
                                </Modal>
                                {cars.filter(o => o.owner === user?._id).map((car) => {
                                    return <div className="col d-flex justify-content-center m-2">
                                        <div className='car p-2 bxs2'>
                                            <img className='carimg img-fluid' src={car.images[0].url} />
                                            <div className='car-content d-flex align-items-center justify-content-between'>
                                                <div>
                                                    <p><u><b>{car.name}</b></u></p>
                                                    <p>${car.rentPerDay} / Day</p>
                                                    {bookings.filter((o) => o.vehicle === car._id).map((booking) => {
                                                        earnings += booking.total;
                                                    })}
                                                    <p><b style={{ color: "dodgerblue" }}>Total Earnings:</b> {earnings}</p>

                                                </div>
                                                <div className="me-4">
                                                    <Link to={`/edit/${car._id}`}><EditOutlined className='me-3' style={{ color: "black" }} /></Link>
                                                    <Popconfirm
                                                        title="Are you sure to delete this car?"
                                                        onConfirm={() => {
                                                            dispatch(deleteCar({ carid: car._id }))
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No" >
                                                        <DeleteOutlined />
                                                    </Popconfirm>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </DefaultLayout >
    )
}

export default UserBookings

    {/* < div className = 'row bxs p-3 mb-4' >
<div className='col-md-4'>
    <img src={booking.image} className='prof-img img-fluid' />
</div>
<div className='col-md-8'>
    <div className='d-flex justify-content-between'>
        <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
        <Popconfirm
            title="Do you need to Edit or Cancel this Trip?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
                window.location = `mailto:admin@roraoftexas.com?subject=Trip ${booking.transactionID}`;
            }}>
            <img src={Edit} width="40" className='p-2' style={{ cursor: "pointer" }} />
        </Popconfirm>
    </div>
    <p>Total Days: <b>{booking.totalDays}</b></p>
    <p>Total Miles: <b>{booking.mileage}</b></p>
    <p>Rate: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.carRate)}</b>/ Day</p>
    <p>Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>

    <div className='d-flex flex-row'><p className='idTag'>Transaction ID:</p><p style={{ overflowY: "scroll" }}><b>{booking.transactionID}</b></p></div>
    {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
    <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
    <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
    <p>Booking Date: <b>{moment(booking.createdAt).format('MMM Do yyyy')}</b></p>
</div>
</div > 


<div className='row bxs p-3 mb-4' >
                                                        <div className='col-md-4'>
                                                            <img src={booking.image} height='150' className='' />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <div className='d-flex justify-content-between'>
                                                                <p><u><b>{cars.find(o => o._id === booking.vehicle)?.name}</b></u></p>
                                                                <Popconfirm
                                                                    title="Do you need to Edit or Cancel this Trip?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    onConfirm={() => {
                                                                        window.location = `mailto:admin@roraoftexas.com?subject=Trip ${booking.transactionID}`;
                                                                    }}>
                                                                    <img src={Edit} width="40" className='p-2' style={{ cursor: "pointer" }} />
                                                                </Popconfirm>
                                                            </div>
                                                            <p>Total Days: <b>{booking.totalDays}</b></p>
                                                            <p>Total Miles: <b>{booking.mileage}</b></p>
                                                            <p>Rate: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.carRate)}</b>/ Day</p>
                                                            <p>Total: <b>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</b></p>

                                                            <div className='d-flex flex-row'><p className='idTag'>Transaction ID:</p><p style={{ overflowY: "scroll" }}><b>{booking.transactionID}</b></p></div>
                                                            {booking.deliveryRequired ? <p>Location: <b>{booking.location}</b></p> : ""}
                                                            <p>From: <b>{moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <p>To: <b>{moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm A')}</b></p>
                                                            <p>Booking Date: <b>{moment(booking.createdAt).format('MMM Do yyyy')}</b></p>
                                                        </div>
                                                    </div>




*/}
