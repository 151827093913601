import axios from "axios";
import { message } from "antd";

//let url = "http://localhost:4000";
let url = "https://api.roraoftexas.com";

export const requestRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/requestride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("Your Ride was Requested");
    setTimeout(() => {
      window.location.href = "/mystuff#nav-ride";
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const approveRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/approveride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("You Approved the Ride");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};
export const acceptRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/acceptride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("You Accepted the Counter Offer");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const declineRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/declineride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("You Declined this Ride");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const payRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/payride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("Ride has been Paid");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const updateRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/updateride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("Ride Info has been Updated");
    /* setTimeout(() => {
      window.location.reload();
    }, 300); */
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};
export const counterRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/counterride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("Ride Counter Offer Sent");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const deleteRide = (reqobj) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });
  try {
    await axios.post(`${url}/api/rides/deleteride`, reqobj);
    dispatch({ type: "LOADING", payload: false });
    message.success("This Ride has been Deleted");
    setTimeout(() => {
      window.location.reload();
    }, 300);
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
    message.error("Something went Wrong");
  }
};

export const getAllRides = () => async (dispatch) => {
  dispatch({ type: "LOADING", payload: true });

  try {
    const response = await axios.get(`${url}/api/rides/getallrides`);
    dispatch({ type: "GET_ALL_RIDES", payload: response.data });
    dispatch({ type: "LOADING", payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: "LOADING", payload: false });
  }
};
