import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { useJsApiLoader, GoogleMap, Marker, Autocomplete, DirectionsRenderer, useLoadScript } from "@react-google-maps/api"
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../redux/actions/userActions';
import { getAllRides, updateRide } from '../redux/actions/rideActions';
import { getOnlineDrivers, getonlineDrivers } from '../redux/actions/driverActions';
import globalVar from '../globalVar';
import sw from "../assests/roraSW.png";
import flag from "../assests/flag.png";
import moment from 'moment';
import { useRef } from 'react';
import { Button, Divider } from 'antd';
import { updateDriver } from '../redux/actions/driverActions';
import useWebSocket from 'react-use-websocket';
//const WS_URL = "ws://127.0.0.1:4000/socket";
const WS_URL = "wss://api.roraoftexas.com/socket";
/*global google*/

function Ride(match) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: globalVar.GMap_Key
    })
    const { rideid } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { rides } = useSelector(state => state.ridesReducer);
    const { users } = useSelector(state => state.usersReducer);
    const { drivers } = useSelector(state => state.driverReducer);
    const [getPassengers, setGetPassengers] = useState(0);
    const [getdriverLat, setDriverLat] = useState(0);
    const [getdriverLong, setDriverLong] = useState(0);
    const [getdriverLatLng, setDriverLatLng] = useState({});
    const [markers, setMarkers] = useState([3]);
    const [center, setCenter] = useState(null);
    const [name, setName] = useState("");
    const [getdriver, setGetdriver] = useState("");
    const [riderID, setRiderID] = useState("");
    const [address, setAddress] = useState("");
    const [destination, setDest] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [visible, setVisible] = useState(false)
    const [complete, setComplete] = useState(false)
    const [pickedUp, setPickedUp] = useState()
    const [tempLoc, setTempLoc] = useState({})
    const [instructions, setInstruction] = useState([])
    const [tempDist, setTempDist] = useState(0)
    const [count, setCount] = useState(false)
    const [mapRef, setMapRef] = useState();
    const { sendMessage } = useWebSocket(WS_URL, {
        share: true,
        filter: () => false,
        onMessage: (msg) => {
            if (user?._id !== getdriver) {
                console.log("Receiving message", msg.data)
                const coo = JSON.parse(msg.data)
                setGetdriver(coo.content.id);
                const lat = parseFloat(coo.content.location.coordinates[0])
                const lng = parseFloat(coo.content.location.coordinates[1])
                setDriverLatLng({ lat, lng })
                setDriverLat(lat)
                setDriverLong(lng)
                console.log(markers)
            }
        }
    });
    const [libraries] = useState(['places']);
    const bounds = new window.google.maps.LatLngBounds();
    const today = new Date();
    const [lastUpdateTime, setLastUpdateTime] = useState(today);
    const minFrequency = 1 * 1000;
    const watchOptions = {
        timeout: 60 * 60 * 1000,
        maxAge: 0,
        enableHighAccuracy: true
    };
    useEffect(() => {
        dispatch(getAllRides())
        dispatch(getAllUsers())
        dispatch(getOnlineDrivers())
        setMarkers([]);
    }, [])
    useEffect(() => {
        rides.filter(o => o._id === rideid).map((ride) => {
            setName(ride.user_name)
            setPickedUp(ride.pickedUp)
            if (user?._id === ride.driver)
                setGetdriver(ride.driver)
            setRiderID(ride.user)
            setComplete(ride.completed)
            setGetPassengers(ride.passengers)
            if (!pickedUp) {
                setAddress(ride.from)
            }
            else {
                setAddress(ride.to)
            }
            geocodeByAddress(ride.from).then(results => {
                getLatLng(results[0]).then(({ lat, lng }) => {
                    setCenter({ lat, lng })
                    setMarkers((prevMarkers) => {
                        return [...prevMarkers, { lat, lng, marker_type: 1 }]
                    });
                })
            })
            geocodeByAddress(ride.to).then(results => {
                getLatLng(results[0]).then(({ lat, lng }) => {
                    setDest({ lat, lng })
                    setMarkers((prevMarkers) => {
                        return [...prevMarkers, { lat, lng, marker_type: 2 }]
                    });
                })
            })
        })
        if (user?._id === getdriver) {
            navigator.geolocation.watchPosition(position => {
                console.log("Watchposition success")
                const { latitude, longitude } = position.coords;
                let driver = {};
                driver.id = getdriver;
                driver.location = {
                    type: "Point",
                    coordinates: [latitude, longitude]
                };
                console.log(position.coords)
                sendMessage(JSON.stringify({
                    type: "location_change",
                    content: driver
                }))
                var now = new Date();
               
                setLastUpdateTime(now);

                if (getdriverLatLng.lat !== driver.location.coordinates[0] || getdriverLatLng.lng !== driver.location.coordinates[1])
                    setDriverLatLng({ lat: driver.location.coordinates[0], lng: driver.location.coordinates[1] })
                
                if (latitude !== getdriverLat)
                    setDriverLat(latitude);
                if (longitude !== getdriverLong)
                    setDriverLong(longitude);
            }, (err) => {
                console.log("Watchposition error", err)
            }, watchOptions);
        }
    }, [rides])

    useEffect(() => {
        setMarkers((prevMarkers) => {
            const driverMarkerPos = prevMarkers.findIndex(m => m.marker_type == 3)
            if (driverMarkerPos > -1) {
                console.log("Diver marker updated")
                prevMarkers[driverMarkerPos]["lat"] = getdriverLat;
                prevMarkers[driverMarkerPos]["lng"] = getdriverLong;
            } else {
                console.log("Diver marker added")
                prevMarkers = [...prevMarkers, { lat: getdriverLat, lng: getdriverLong, marker_type: 3 }]
            }
            return prevMarkers
        });
    }, [getdriverLat, getdriverLong])


    useEffect(() => {
        console.log("Markers updated", markers, mapRef)
        for (let i = 0; i < markers.length; i++) {
            let marker = markers[i]
            console.log(marker)
            if (marker.lat != 0 && marker.lng != 0) {
                bounds.extend(new google.maps.LatLng({ lat: marker.lat, lng: marker.lng }));
            }
        }
        mapRef?.fitBounds(bounds);
        console.log("Bounds Data", bounds)
    }, [markers, mapRef]);

    async function calculateRoute(x, y) {
        if (!x || !y) {
            return
        }
        console.log("calculateRoute", x, y)
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: x,
            destination: y,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,
        })
        console.log(results)
        setDirectionsResponse(results)
        setDistance(results.routes[0].legs[0].distance.value)
        setDuration(results.routes[0].legs[0].duration.text)
        setInstruction(results.routes[0].legs[0].steps)
        setVisible(true)
    }

    const ref = useRef();

    useEffect(() => {
        if (!pickedUp) {
            calculateRoute(getdriverLatLng, center)
        }
        else {
            if (count === false) {
                calculateRoute(getdriverLatLng, destination)
                //console.log("here")
                if (distance.length !== 0) {
                    setCount(true);
                    setTempLoc(getdriverLatLng)
                    setTempDist(distance)
                }
            }
            else {
                if (tempLoc !== getdriverLatLng) {
                    calculateRoute(getdriverLatLng, destination)
                    setTempDist(distance)
                    setTempLoc(getdriverLatLng)
                }
            }

        }
    }, [getdriverLatLng, distance, pickedUp, tempDist, tempLoc]);

    if (user?.role === "system_admin") {
        console.log(getdriverLatLng)
    }

    const update = () => {
        rides.filter(o => o._id === rideid).map((ride) => {
            ride.start = moment(today).format("MMM DD YYYY h:mm A");
            ride.pickedUp = true;
            dispatch(updateRide(ride))
        })
    }

    return isLoaded && center ? (
        <DefaultLayout>
            <div className='container'>
                <div className='bxs p-3 textColor'>
                    <div className='row'>
                        <h5>{name}'s Ride</h5>
                        {!complete ? <div className='col-md-12'>
                            {user?._id !== riderID ? <GoogleMap mapContainerClassName="map-container" options={{ mapTypeControl: false, streetViewControl: false }} center={!pickedUp ? center : { lat: getdriverLat, lng: getdriverLong }} zoom={8} mapContainerStyle={{ width: '100%', height: '370px' }}
                                onLoad={(map) => {
                                    setMapRef(map);
                                }}>
                                {!pickedUp ? <Marker position={center} label={name} /> : ""}
                                {getdriverLat && getdriverLong ? <Marker position={{ lat: getdriverLat, lng: getdriverLong }} required icon={{
                                    url: sw,
                                    scaledSize: new google.maps.Size(35, 35)
                                }} /> : null}
                                {destination ? <Marker position={destination} icon={{
                                    url: flag,
                                    scaledSize: new google.maps.Size(45, 45)
                                }} /> : null}
                                {user?._id !== riderID ? directionsResponse && pickedUp && (
                                    <DirectionsRenderer directions={directionsResponse} options={{
                                        suppressMarkers: true
                                    }} />
                                ) : ""}
                            </GoogleMap> : <img className='prof-img img-fluid' src={drivers.find(o => o.id === getdriver)?.image} />}
                        </div>
                            : <Divider />}
                    </div>
                    <div className='row pt-3'>
                        <div className='col-sm-12'>
                            {(complete === true && user?.role !== "system_admin") ? <>
                                <div className='d-flex justify-content-center'>
                                    <p><b style={{ color: "dodgerblue" }}>Ride is Complete</b></p>
                                </div>
                                {user?._id === riderID ?
                                    <button className='btm1'><Link to='/mystuff' style={{ textDecoration: "none" }}>Click Here</Link></button>
                                    : user?._id === getdriver ?
                                        <button className='btm1'><Link to='/driver' style={{ textDecoration: "none" }}>Click Here</Link></button>
                                        : ""}
                            </>
                                : user?._id === riderID ? <>
                                    <div className='row'>
                                        <div className='col-sm-12 d-flex justify-content-start'>
                                            <p><b style={{ color: "dodgerblue" }}>Driver:</b> {getdriver ? users.find(o => o._id === getdriver)?.name.split(" ")[0] + " " + users.find(o => o._id === getdriver)?.name.split(" ")[1].charAt(0) : ""}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 d-flex justify-content-start'>
                                            <p><b style={{ color: "dodgerblue" }}>Car: </b>{drivers.find(o => o.id === getdriver)?.carName}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 d-flex justify-content-start'>
                                            <p><b style={{ color: "dodgerblue" }}>License Plate:</b> {drivers.find(o => o.id === getdriver)?.plate}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='d-flex justify-content-start'>
                                            {!pickedUp ? <p><b style={{ color: "dodgerblue" }}>Driver ETA:</b> {duration ? duration : "Loading..."}</p> : <p><b style={{ color: "dodgerblue" }}>ETA to Destination:</b> {duration ? duration : "Getting Destination ETA"}</p>}
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-around'>
                                        {!pickedUp ? <button className='btm1'><a href={"sms:" + drivers.find(o => o.id === getdriver)?.phone} style={{ textDecoration: "none" }}>Contact Driver</a></button> : ""}
                                        <button className='btm2'><a href="sms:7373145981" style={{ textDecoration: "none", color: "red" }}>Help</a></button>
                                    </div>
                                </>
                                    : user?._id === getdriver ? <>
                                        <div>
                                            <p><b style={{ color: "dodgerblue" }}>Address:</b> {address}</p>
                                            <p><b style={{ color: "dodgerblue" }}>Map: </b>
                                                <a className='linkClass' href={`http://maps.apple.com/?q=${address}`}>Apple</a>
                                                &nbsp;
                                                <a className='linkClass' href={`https://maps.google.com/?q=${address}`}>Google</a>
                                            </p>
                                            {!pickedUp ? <p><b style={{ color: "dodgerblue" }}>Time to {name}:</b> {duration ? duration : "Getting time to Rider"}</p> : <p><b style={{ color: "dodgerblue" }}>Time to Destination:</b> {duration ? duration : "Getting time to Destination"}</p>}
                                            <p><b style={{ color: "dodgerblue" }}>Passengers:</b> {getPassengers}</p>
                                        </div>
                                        <div className='pt-3 d-flex justify-content-around'>
                                            {!pickedUp ? <><button className='btm'><a href={"sms:" + users.find(o => o._id === riderID)?.phone} style={{ textDecoration: "none", color: "gray" }}>Contact Rider</a></button>
                                                <button className='btm1' onClick={() => {
                                                    setPickedUp(true);
                                                    update();
                                                }}>Pick Up</button>
                                            </> : <button className='btm1' onClick={() => {
                                                rides.filter(o => o._id === rideid).map((ride) => {
                                                    ride.finished = moment(today).format("MMM DD YYYY h:mm A");
                                                    ride.completed = true;
                                                    dispatch(updateRide(ride)).then(()=>{
                                                        window.location = '/driver'
                                                    })
                                                })
                                            }}>Complete Ride</button>}
                                            <button className='btm2'><a href="sms:7373145981" style={{ textDecoration: "none", color: "red" }}>Help</a></button>
                                        </div>
                                    </> : (user?.role === "system_admin" && (user?._id !== getdriver.id || riderID)) ? <>
                                        <div className='pt-4'>
                                            <div className='row'>
                                                <div className='col-sm-3 pt-2'><img className='prof-img img-fluid' src={drivers.find(o => o.id === getdriver)?.image} width="150" /></div>
                                                <div className='col-sm-9'>
                                                    <p><b style={{ color: "dodgerblue" }}>Driver:</b> {getdriver ? users.find(o => o._id === getdriver)?.name.split(" ")[0] + " " + users.find(o => o._id === getdriver)?.name.split(" ")[1].charAt(0) : ""}</p>
                                                    <p><b style={{ color: "dodgerblue" }}>Car: </b>{drivers.find(o => o.id === getdriver)?.carName}</p>
                                                    <p><b style={{ color: "dodgerblue" }}>License Plate:</b> {drivers.find(o => o.id === getdriver)?.plate}</p>
                                                </div>
                                            </div>
                                            <Divider />
                                            <p><b style={{ color: "dodgerblue" }}>Rider:</b> {name}</p>
                                            <p><b style={{ color: "dodgerblue" }}>Address:</b> {address}</p>
                                            {!pickedUp ? <p><b style={{ color: "dodgerblue" }}>Time to {name}:</b> {duration ? duration : "Getting time to Rider"}</p> : <p><b style={{ color: "dodgerblue" }}>Time to Destination:</b> {duration ? duration : "Getting time to Destination"}</p>}
                                            <p><b style={{ color: "dodgerblue" }}>Passengers:</b> {getPassengers}</p>
                                        </div>
                                    </> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    ) : "Error"
}

export default Ride
