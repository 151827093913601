import React, { useEffect, useState } from 'react';
import {
  CardElement,
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { Form, Checkbox, Modal, Divider, } from "antd";
import { bookCar } from '../redux/actions/bookingActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import globalVar from '../globalVar';
import emailjs from '@emailjs/browser';
import moment from "moment";
import { payRide } from '../redux/actions/rideActions';

const StripeCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const location = useLocation();
  const reqobj = (!location.state.ride === false) ? location.state.ride : location.state;
  const [paymessage, setPayMessage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const loggedIN = JSON.parse(localStorage.getItem('user'));
  const username = loggedIN?.name?.split(" ");
  //console.log(username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1));
  const dinero = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(reqobj.total)

  const [chk, setChk] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChange = (e) => {
    setChk(e.target.checked);
    //console.log(`checked = ${e.target.checked}`);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  }

  const handleSubmit = async (e) => {
    //e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/mystuff`
      },
      redirect: "if_required",
    });

    console.log(error)
    //console.log(paymentIntent)

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setPayMessage(error.message)
      }
    }
    else if (paymentIntent && paymentIntent.status === "requires_capture") {
      paymentIntent.receipt_email = reqobj.email;
      if (reqobj.type !== "ride") {
        dispatch(bookCar(reqobj));
        //console.log(reqobj);
        localStorage.setItem("session", "");

        emailjs.send(globalVar.Gmail_SRV, globalVar.Booked_Trip, {
          to_name: username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1),
          email: loggedIN.email,
          message: `${reqobj.name}\nFrom: ${moment(reqobj.bookedTimeSlots.from).format('dddd, MMM Do yyyy, h:mm A')}\nTo: ${moment(reqobj.bookedTimeSlots.to).format('dddd, MMM Do yyyy, h:mm A')}\n${reqobj.location}\n${dinero}`,
        }, globalVar.GMail_Key).then(function (res) {
          console.log("Email Sent " + res.status)
        });

        emailjs.send(globalVar.Gmail_SRV, globalVar.Booked_Trip_Admin, {
          message: `${reqobj.name}\nFrom: ${moment(reqobj.bookedTimeSlots.from).format('dddd, MMM Do yyyy, h:mm A')}\nTo: ${moment(reqobj.bookedTimeSlots.to).format('dddd, MMM Do yyyy, h:mm A')}\n${reqobj.totalDays} Days\n${reqobj.location}\nCharge - ${reqobj.extras.charge}\nFuel - ${reqobj.extras.fuel}\nToll - ${reqobj.extras.toll}\nClean - ${reqobj.extras.clean}\n${dinero}`,
        }, globalVar.GMail_Key).then(function (res) {
          console.log("Email Sent " + res.status)
        })
      }
      else {
        dispatch(payRide(reqobj))
        console.log(reqobj)
        localStorage.setItem("session", "");

        emailjs.send(globalVar.Gmail_SRV, globalVar.Pay_Confirm, {
          to_name: reqobj.user_name,
          email: reqobj.email,
          message: `${reqobj.date}\nFrom: ${reqobj.from}\nTo: ${reqobj.to}\nDistance: ${reqobj.results.distance}\nDuration: ${reqobj.results.duration}\n${(reqobj.passengers > 1) ? ("Passengers: " + reqobj.passenger) : ""}\n${dinero}`,
        }, globalVar.GMail_Key).then(function (res) {
          console.log("Email Sent " + res.status)
        });

        emailjs.send(globalVar.Gmail_SRV, globalVar.Pay_Confirm_Admin, {
          to_name: reqobj.user_name,
          message: `${reqobj.date}\nFrom: ${reqobj.from}\nTo: ${reqobj.to}\nDistance: ${reqobj.results.distance}\nDuration: ${reqobj.results.duration}\n${(reqobj.passengers > 1) ? ("Passengers: " + reqobj.passenger) : ""}\n${dinero}`,
        }, globalVar.GMail_Key).then(function (res) {
          console.log("Email Sent " + res.status)
        });
      }

      setTimeout(() => {
        window.location.href = "/mystuff";
      }, 1200);
    }
    else {
      setPayMessage("An unexpected state.")
    }

    setProcessing(false);

  }

  return (
    <Form onFinish={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <div className=' pt-4'><Checkbox onChange={onChange}>Agree to</Checkbox><a onClick={showModal}><u>Terms &amp; Conditions</u></a></div>
      <Modal title="Terms &amp; Conditions" open={isModalOpen} onCancel={handleCancel} footer={[]}>
        <Divider />
        <p>You must provide your driver's license &amp; full coverage car insurance before trip start.<br /><br />
          <u>Charges</u><br />
          - Cost of your rental<br />
          - Delivery (if selected)<br />
          - Deposit (refunded pending no violations)<br />
          - Taxes<br />
          - Under 25 Fee (if applies)<br />
          - Extras (if selected):<br />&nbsp;&nbsp;&nbsp;Toll Access<br />&nbsp;&nbsp;&nbsp;Prepaid Fuel/Electric Charge<br />&nbsp;&nbsp;&nbsp;Post-Trip Cleaning<br /><br />
          <u>Violation Fees</u><br />
          - Toll (if no Toll Access Extra):<br />&nbsp;&nbsp;&nbsp;Toll charges + $15 Inconvenience Fee<br />
          - Inadequate Fuel or Electric Charge<br />(Rental must be returned at same amount of fuel or electric charge):<br />
          &nbsp;&nbsp;&nbsp;Cost of gas/charging + $10 Inconvenience Fee<br />
          - Cleaning/Smoking Violation:<br />&nbsp;&nbsp;&nbsp;Subject up to $150<br />
          - Late Fee (if rental is returned late):<br />
          &nbsp;&nbsp;&nbsp;$30 under 30min, $45 per 30min<br /><br />

          <b>Refund Policy</b><br />
          - <u>NO REFUNDS</u> for cancellations within 24 hours or less of trip start time.<br />
          - <u>PARTIAL REFUNDS</u> for cancellations between 36-24 hours before trip start.<br />
          - <u>FULL REFUNDS</u> for cancellations 36+ hours before trip start.</p>
      </Modal>
      <button id='payButton' className='btm1 mt-4 mb-2' disabled={processing || !stripe || !elements || chk === false}>
        <span>
          {processing ? "Booking..." : "Pay Now"}
        </span>
      </button>
      {paymessage && <div id="payment-message">{paymessage}</div>}
    </Form>
  )
}

export default StripeCheckout