import React from 'react'

export default {
    Gmail_SRV: "service_4py12lk",
    Booked_Trip:"template_lv68rwf",
    Booked_Ride:"template_qtlzhz8",
    Booked_Trip_Admin:"template_q8nbmni",
    Ride_Request:"ride_request",
    Admin_RideRequest:"riderequest_admin",
    Pay_Reminder:"ridepay_reminder",
    Pay_Confirm:"pay_confirm",
    Pay_Confirm_Admin:"ridepayconfirm_admin",
    Counter_Offer:"counter_offer",
    Registration:"template_l4v1frn",
    PW_Reset:"template_i7gnfic",
    PW_Reset_Confirm:"template_gr0hg79",
    GMail_Key: "fawm5WsMYFi9RIcZa",
    GMap_Key: "AIzaSyBuppSKBR8YmkTlwqfxTVrubDVvtyYB3DQ",
    //url: "http://localhost:4000",
    url: "https://api.roraoftexas.com",
    stripe_Key_test: "pk_test_51M9XuxGYrPg1epLHWriPuU5Cgd52f12UgzXLfJ7VigsN2Wkn1d9p4wq9U2QZJRueq6sKqRUITTxsPTsDHvcGfHRM00fwmZ8ibo",
    stripe_Key_live: "pk_live_51M9XuxGYrPg1epLH9lWr6IffrhHFq85IngHIIwph7iKLrf5aGHdXl8ERJoCJor6RWh0LkVNEEB1OMupYNcwHhVEz00cJGsJ2Nl"
};