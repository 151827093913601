import React, { useEffect, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { useSelector, useDispatch } from 'react-redux';
import { getAllRides, updateRide } from '../redux/actions/rideActions';
import { getAllUsers, } from '../redux/actions/userActions';
import { getAllCars } from '../redux/actions/carsActions';
import { Divider, Form, Select, Option, Input, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { driverOffline, getOnlineDrivers, goOnline } from '../redux/actions/driverActions';

function Driver() {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { rides } = useSelector(state => state.ridesReducer);
    const { cars } = useSelector(state => state.carsReducer);
    const { users } = useSelector(state => state.usersReducer);
    const { drivers } = useSelector(state => state.driverReducer);
    const user = JSON.parse(localStorage.getItem("user"));
    const [getdriver, setDriver] = useState({});
    const [coords, setCoords] = useState({});
    const [carValue, setcarValue] = useState("");
    const [clicked, setclicked] = useState(false);
    const [prog, setprogress] = useState(false);
    const [car, setCar] = useState("");
    var count;
    var count1;
    var count2 = 0;
    var onlineDriver;
    //localStorage.setItem("driver", getdriver);
    useEffect(() => {
        dispatch(getAllRides())
        dispatch(getAllCars())
        dispatch(getAllUsers())
        dispatch(getOnlineDrivers())
    }, [])

    navigator.geolocation.watchPosition(position => {
        console.log("Watchposition success")
        const { latitude, longitude } = position.coords;
        setCoords({ type: 'Point', coordinates: [latitude, longitude], });
    })

    function onFinish(values) {
        if (carValue === "rora") {
            values.carName = cars.find(o => o._id === values.carID)?.name
            values.plate = cars.find(o => o._id === values.carID)?.plate
            values.image = cars.find(o => o._id === values.carID)?.images[0].url
            values.id = user._id;
            values.location = coords;
            dispatch(goOnline(values))
        }
        else {
            values.id = user._id;
            dispatch(goOnline(values))
        }
        console.log(values)
    }

    drivers.filter((o) => o.id === user?._id).map((driver) => {
        onlineDriver = driver;
    })

    return (
        <DefaultLayout>
            <div id='driverView' className='container textColor'>
                {user?.role === "system_admin" ? <div className='row p-1'>
                    <div className='col-md-12'>
                        <h3 className='pt-3' style={{ color: "green" }}>In Progress</h3>
                        <Divider />
                        {rides.map((ride) => {
                            console.log(ride)
                            count = 0
                            if ((ride.driver?.id === user._id || user?.role === "system_admin") && ride.enroute && !ride.completed) {
                                count++;
                                return <div className='row p-1'>
                                    <div className='col-md-12'>
                                        <div className='bxs p-3 mb-3'>
                                            {user?.role === "system_admin" && ride.driver ? <p><b style={{ color: "dodgerblue" }}>Driver</b>: {users.find(o => o._id === ride.driver.id)?.name}</p> : ""}
                                            <p><b>Origin</b>: {ride.from}</p>
                                            <p><b>Destination</b>: {ride.to}</p>
                                            {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.passengers}</p> : ""}
                                            <div className='d-flex justify-content-center'>
                                                <button className='btm1'><Link to={`/ride/${ride._id}`} style={{ textDecoration: "none" }}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        })}
                        {count === 0 ? <p className='d-flex justify-content-center'>No Rides in Progress</p> : ""}
                    </div>
                </div> : onlineDriver ? <div className='row p-3 views'>
                    <div className='col-md-12' id="progDiv">
                        <h3 className='pt-3' style={{ color: "green" }}>In Progress</h3>
                        <Divider />
                        {rides.map((ride) => {
                            console.log(ride)
                            count1 = 0;
                            if (ride.driver === user._id && ride.enroute && !ride.completed) {
                                console.log(ride);
                                count1++;
                                return <div className='row p-1'>
                                    <div className='col-md-12'>
                                        <div className='bxs p-3 mb-3'>
                                            {user?.role === "system_admin" && ride.driver ? <p><b style={{ color: "dodgerblue" }}>Driver</b>: {users.find(o => o._id === ride.driver.id)?.name}</p> : ""}
                                            <p><b>Origin</b>: {ride.from}</p>
                                            <p><b>Destination</b>: {ride.to}</p>
                                            {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.passengers}</p> : ""}
                                            <div className='d-flex justify-content-center'>
                                                <button className='btm1'><Link to={`/ride/${ride._id}`} style={{ textDecoration: "none" }}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        })}
                        {count1 === 0 ? <p className='d-flex justify-content-center'>No Rides in Progress</p> : ""}
                    </div>
                </div>
                    : ""}
                <div id='waiting' className='row p-3 views'>
                    <div className='col-md-12'>
                        <h3 className='pt-3'>Waiting Rides</h3>
                        <Divider />
                        {rides.map((ride) => {
                            if (ride.paid && !ride.completed && !ride.enroute) {
                                count2++;
                                return <div className='bxs p-3 mb-3'>
                                    <p><b>Date</b>: {ride.date}</p>
                                    <p><b>Origin</b>: {ride.from}</p>
                                    <p><b>Destination</b>: {ride.to}</p>
                                    {ride.passengers > 1 ? <p><b>Passengers</b>: {ride.passengers}</p> : ""}
                                    <p><b>Distance</b>: {ride.results.distance}</p>
                                    <p><b>Duration</b>: {ride.results.duration}</p>
                                    {count === 0 ?
                                        <div className='d-flex justify-content-center'>
                                            {onlineDriver ?
                                                <button className='btm1' onClick={() => {
                                                    ride.enroute = true;
                                                    ride.driver = onlineDriver;
                                                    dispatch(updateRide(ride))
                                                    //console.log(getdriver)
                                                    //console.log(ride)
                                                    setTimeout(() => {
                                                        window.location = `/ride/${ride._id}`;
                                                    }, 300);
                                                }}>Start</button> : <Tooltip title="Please Go Online"><button disabled>Start</button></Tooltip>}
                                        </div>
                                        : ""}
                                </div>
                            }
                        })}
                        {count2 === 0 ? <p className='d-flex justify-content-center'>No Rides Waiting</p> : ""}
                    </div>
                </div>
                {!onlineDriver && user?.role !== "system_admin" ? <>
                    <Divider id='startDivider' />
                    <button id='startButton' className='btm-go' onClick={() => {
                        document.getElementById('startButton').style.display = "none";
                        document.getElementById('startDivider').style.display = "none";
                        var x = document.getElementsByClassName('views');
                        var i;
                        for (i = 0; i < x.length; i++) {
                            x[i].style.display = 'none';
                        };
                        setclicked(true)
                    }}>Go Online</button>{clicked ? <div className='bxs p-3'>
                        <h3>Go Online</h3>
                        <Form onFinish={onFinish}>
                            <Form.Item>
                                <Select onChange={(e) => setcarValue(e)}>
                                    <Option value="rora">Rora Car</Option>
                                    <Option value="user">Your Car</Option>
                                </Select>
                            </Form.Item>
                            {!user.phone ?
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                :
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true }]}>
                                    <Input placeholder={user.phone} disabled />
                                </Form.Item>
                            }
                            {carValue === "rora" ? <>
                                <Form.Item name='carID' label="Vehicle Name" rules={[{ required: true }]}>
                                    <Select onChange={(e) => setCar(e)}>
                                        {cars.filter(o => o.type === "car").map((car) => {
                                            return (<Option value={car._id}>{car.name}</Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item name='plate' label="License Plate">
                                    <Input placeholder={cars.find(o => o._id === car)?.plate} disabled />
                                </Form.Item>
                            </>
                                : <>
                                    <Form.Item name='carName' label="Vehicle Make &amp; Model" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name='color' label="Vehicle Color" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name='plate' label="License Plate" rules={[{ required: true }]}>
                                        <Input className='toUpper' maxLength="7" />
                                    </Form.Item>
                                </>}
                            <div className='d-flex flex-column justify-content-center'>
                                <div className='m-1'><button className='btm-go'>Start</button></div>
                                <div className='m-1'><button className='btm2' onClick={() => {
                                    document.getElementById('startButton').style.display = "inline";
                                    document.getElementById('startDivider').style.display = "inline";
                                    var x = document.getElementsByClassName('views');
                                    var i;
                                    for (i = 0; i < x.length; i++) {
                                        x[i].style.display = 'inline';
                                    };
                                    setclicked(false)
                                }}>Cancel</button></div>
                            </div>
                        </Form>
                    </div> : ""}</>
                    : onlineDriver && user?.role !== "system_admin" && prog ? <>
                        <Divider id='startDivider' />
                        <Tooltip title="Finish In Progress ride"><button disabled>Go Offline</button></Tooltip>
                    </>
                        : onlineDriver && user?.role !== "system_admin" && !prog ? <>
                            <Divider id='startDivider' />
                            <button className='btm2' onClick={() => {
                                dispatch(driverOffline(onlineDriver));
                                onlineDriver = {};
                            }} >Go Offline</button>
                        </> : ""}
            </div>
        </DefaultLayout>
    )

} 

export default Driver