import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout';
import { editBooking, getAllBookings } from '../redux/actions/bookingActions';
import moment from 'moment';
import { getAllCars } from '../redux/actions/carsActions';
import { Divider, Form, Input, InputNumber, Radio, Space } from 'antd';
import { getAllUsers } from '../redux/actions/userActions';


function Reservations(match) {
    const { bookingid } = useParams();
    const dispatch = useDispatch();
    const { bookings } = useSelector(state => state.bookingsReducer);
    const { cars } = useSelector(state => state.carsReducer);
    const { users } = useSelector(state => state.usersReducer);
    const user = JSON.parse(localStorage.getItem("user"));
    const [radio, setRadio] = useState("");
    const [total, setTotal] = useState(0);

    const setDamage = (e) => {
        setRadio(e.target.value);
    };

    function onFinish(values) {
        values.completed = true
        values.id = bookingid;
        dispatch(editBooking(values));
    }

    useEffect(() => {
        dispatch(getAllCars())
        dispatch(getAllBookings())
        dispatch(getAllUsers())
    }, [])

    useEffect(() => {
        bookings.filter(o => o._id === bookingid).map((booking) => {
            if (booking.post)
                setTotal(booking.total - cars.find((o) => o._id === booking.vehicle)?.deposit + (booking.post.milesOverage ? booking.post.milesOverage : 0) + (booking.post.toll ? booking.post.toll : 0) + (booking.post.clean ? booking.post.clean : 0) + (booking.post.elecCharge ? booking.post.elecCharge : 0) + (booking.post.fuel ? booking.post.fuel : 0));
        })
    }, [total])

    return (
        <DefaultLayout>
            <div className='container textColor'>
                <div className='bxs'>
                    {bookings.filter(o => o._id === bookingid).map((booking) => {
                        console.log(booking)
                        return <div className='row p-3'>
                            <div className='col-sm-4'>
                                <img src={booking.image} height='150' className='p-2' />
                            </div>
                            <div className='col-sm-4'>
                                <p><u><b>{cars.find((o) => o._id === booking.vehicle)?.name}</b></u></p>
                                <p style={{ color: "dodgerblue" }}><b>{users.find(o => o._id === booking.user)?.name}</b></p>
                                <p><b>Booking Date:</b> {moment(booking.createdAt).format('MMM Do yyyy')}</p>
                                <p><b>From:</b> {moment(booking.bookedTimeSlots.from, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm a')}</p>
                                <p><b>To:</b> {moment(booking.bookedTimeSlots.to, "MM-DD-YYYY HH:mm:ss A").format('dddd, MMM Do yyyy, h:mm a')}</p>
                                {booking.delivery ? <p><b>Delivery:</b> {booking.location}</p> : <p><b>Pick Up:</b> {booking.location}</p>}
                                <p><b>Daily Rate:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.carRate)}</p>
                                <p><b>Deposit:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cars.find((o) => o._id === booking.vehicle)?.deposit)}</p>
                                {booking.extras.charge ? <p><b>Pre-Paid Charging:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(25)}</p> : ""}
                                {booking.extras.clean ? <p><b>Pre-Paid Post-Trip Cleaning:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(15)}</p> : ""}
                                {booking.extras.fuel ? <p><b>Pre-Paid Refueling:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(40)}</p> : ""}
                                {booking.extras.toll ? <p><b>Pre-Paid Toll Usage:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(20)}</p> : ""}
                                <p><b>Booking Total:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.total)}</p>
                                <p><b>Allowed Mileage:</b> {booking.mileage}</p>
                                {user?.role === "system_admin" ? <div className='d-flex flex-row'><p className='idTag'><b>Transaction ID:</b></p><p style={{ overflowY: "scroll" }}>{booking.transactionID}</p></div> : ""}
                            </div>
                            <div className='col-sm-4'>
                                {(!booking.completed && user?.role === "system_admin") ?
                                    <Form onFinish={onFinish}>
                                        <Form.Item name='miles' label="Driven Mileage" rules={[{ required: true }]}>
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item name='toll' label="Toll Charges" rules={[{ required: true }]}>
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item name='cleaning' label="Post-Trip Cleaning" rules={[{ required: true }]}>
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                        {cars.find((o) => o._id === booking.vehicle)?.fuelType === "Electric" ?
                                            <Form.Item name='elec' label="Electric Recharging" rules={[{ required: true }]}>
                                                <InputNumber style={{ width: "100%" }} />
                                            </Form.Item> : <Form.Item name='fuel' label="Refueling" rules={[{ required: true }]}>
                                                <InputNumber style={{ width: "100%" }} />
                                            </Form.Item>
                                        }
                                        <Form.Item name='damage' label="Damage?" rules={[{ required: true }]}>
                                            <Radio.Group className='d-flex justify-content-start' onChange={setDamage} value={radio}>
                                                <Space direction="vertical" style={{ alignItems: "flex-start" }}>
                                                    <Radio value={true}>Yes</Radio>
                                                    <Radio value={false}>No</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        <button className='btm1'>Submit</button>
                                    </Form>
                                    : (!booking.completed && user?.role !== "system_admin") ?
                                        <p style={{ color: "dodgerblue" }}>Awaiting completion details from Admin</p>
                                        : (booking.completed) ? <>
                                            <p><b style={{ color: "dodgerblue" }}>Miles Driven:</b> {booking.driven}</p>
                                            {booking.post.milesOverage ? <p><b style={{ color: "dodgerblue" }}>Miles Overage Charge:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.post.milesOverage)}</p> : ""}
                                            {booking.post.toll !== 0 ? <p><b style={{ color: "dodgerblue" }}>Toll Charges:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.post.toll)}</p> : ""}
                                            {booking.post.clean !== 0 ? <p><b style={{ color: "dodgerblue" }}>Cleaning Fee:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.post.clean)}</p> : ""}
                                            {booking.post.elecCharge && booking.post.elecCharge !== 0 ? <p><b style={{ color: "dodgerblue" }}>Electric Charging Fee:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.post.elecCharge)}</p> : ""}
                                            {booking.post.fuel && booking.post.fuel !== 0 ? <p><b style={{ color: "dodgerblue" }}>Refueling Fee:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(booking.post.fuel)}</p> : ""}
                                            {booking.post.damage ? <p><b style={{ color: "red" }}>Damage - Escalated to Admin</b></p> : ""}
                                            <Divider />
                                            <p><b style={{ color: "dodgerblue" }}>Final Trip Total:</b> {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</p>
                                        </> : ""}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Reservations