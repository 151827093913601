import React, {useState} from "react";
import ML from "../assests/roraMain.png";
import admin from "../assests/admin.png";
import profile from "../assests/user.png";
import home from "../assests/home.png";
import sw from "../assests/roraSW.png";
import MSG from "../assests/message.png";
import O from "../assests/O-Logo.png";
import G from "../assests/google.png";
import faq from "../assests/faq.png";
import phone from "../assests/phone.png";
import email from "../assests/email.png";
import contact from "../assests/contact.png";
import car from "../assests/menu_car.png";
import { Divider, Modal } from "antd";

function DefaultLayout(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const username = user?.name?.split(" ");
  const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
};
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="myNAV p-3 mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <a href="/">
            <img src={ML} width="250" className="navIMG" />
          </a>
          <div className="dropdown text-end">
            <a
              className="d-flex link-dark text-decoration-none dropdown-toggle show align-items-center"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              <p>
                {user
                  ? "Let's Go, " + username[0]?.charAt(0)?.toUpperCase() + username[0]?.slice(1)
                  : "Lets Go"}
              </p>
            </a>
            <ul
              className="dropdown-menu text-small"
              data-popper-placement="bottom-end"
            >
              <li className="d-inline-flex align-items-center">
                <img src={home} className="ps-2 menuIMG" />
                <a className="dropdown-item" href="/">
                  Home
                </a>
              </li>
              {user?.role === "system_admin" ? (
                <li className="d-inline-flex align-items-center">
                  <img src={admin} className="ps-2 menuIMG" />
                  <a className="dropdown-item" href="/admin">
                    Admin
                  </a>
                </li>
              ) : (
                ""
              )}
              {user && (user?.role === "system_admin" || user?.role === "driver") ? (
                <li className="d-inline-flex align-items-center">
                  <img src={car} className="ps-1 menuIMG2" />
                  <a className="dropdown-item" href="/driver">
                    Drive
                  </a>
                </li>
              ) : (
                ""
              )}
{/*               </ul>{user && (user?.role !== "system_admin") ? (
 */}              {user  ? (
                <li className="d-inline-flex align-items-center">
                  <img src={profile} className="ps-2 menuIMG" />
                  <a className="dropdown-item" href="/mystuff">
                    My Stuff
                  </a>
                </li>
              ) : (
                ""
              )}
              <li className="d-inline-flex align-items-center">
                <img src={sw} className="ps-2 menuIMG" />
                <a className="dropdown-item" href="/about">
                  About Us
                </a>
              </li>
              <li className="d-inline-flex align-items-center">
                <img src={faq} className="ps-2 menuIMG" />
                <a className="dropdown-item" href="/faq">
                  FAQ
                </a>
              </li>
              <li className="d-inline-flex align-items-center">
                <img src={contact} className="ps-2 menuIMG" />
                <a className="dropdown-item" onClick={showModal}>
                  Contact Us
                </a>
                <Modal title="Contact Rora" open={isModalOpen} onCancel={handleCancel} footer={[]}>
                  <Divider/>
                  <div>
                    <div>
                      <img src={email} width="32.5" className="me-2"/>
                      <a href="mailto: admin@roraoftexas.com" style={{textDecoration: "none", color: "black"}}><b><u>Admin@RoraofTexas.com</u></b></a>
                    </div>
                    <br/>
                    <div>
                      <img src={phone} width="32.5" className="me-2"/>
                      <a href="tel: 7373145981" style={{textDecoration: "none", color: "black"}}><u><b>(737) 314-5981</b></u></a>
                    </div>
                  </div>
                </Modal>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {user ? (
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      localStorage.removeItem("user");
                      window.location.href = "/login";
                    }}
                  >
                    Logout
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      localStorage.removeItem("user");
                      window.location.href = "/login";
                    }}
                  >
                    Login/Sign Up
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="content">{props.children}</div>
      <div className="msg">
        <a href="sms:7373145981">
          <img src={MSG} className="" width="25" />
        </a>
      </div>
      <div className="footer mt-auto textColor">
        <Divider />
        <div className="d-flex justify-content-center align-items-center">
          <p>Find us on </p>
          <a href="https://g.page/r/Ce_1qQZIHjdXEAI/review" target="_blank">
            <img src={G} width="25" />
          </a>
        </div>
        <div className="d-flex justify-content-center">
          <p>&copy; 2024 Rora. Created by&nbsp;
            <a href="http://www.odisreese.com" target="_blank">
              <img src={O} width="30" />
            </a></p>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;