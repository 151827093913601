import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefaultLayout from "../components/DefaultLayout";
import { Button, DatePicker, Modal, Form, Input, Divider } from 'antd';
import { signUp } from '../redux/actions/customerActions'
import { Link } from "react-router-dom";
import Ride from '../assests/carpool2.png';
import Rent from '../assests/rental.png';
import Park from '../assests/parking.png';
import Ad from '../assests/adCar.png';

const { RangePicker } = DatePicker;

function Home() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function onFinish(values) {
    values.reason = "main";
    values.comment = "Rora News"
    dispatch(signUp(values))
    setIsModalOpen(false);
    //console.log(values);
  }

  return (
    <DefaultLayout>
      <div className="container">
        <div className="row">
          <div className='col-md-4 d-flex justify-content-center'>
            <div className='aboutBOX bxs2 p-2 pb-3'>
              <img className='m-2' src={Ride} width="65px" />
              <h6 className='text-center' style={{ color: "black" }}>Rides</h6>
              <div className="pt-2">
                <a className="btm1 p-1 mr-2" href="/bookride" style={{ textDecoration: "none" }}>Book a Ride</a>
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex justify-content-center'>
            <div className='aboutBOX bxs2 p-2 pb-3'>
              <img className="pb-3" src={Rent} width="100px" />
              <h6 className='text-center textColor'>Rentals</h6>
              <div className="pt-2">
                <a className="btm1 p-1 mr-2" href="/rent" style={{ textDecoration: "none" }}>View Rentals</a>
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex justify-content-center'>
            <div className='aboutBOX bxs2 p-2 pb-3'>
              <img className="pb-2" src={Park} width="80" />
              <h6 className='text-center textColor'>Storage &amp; Parking</h6>
              <p className='text-center' style={{ color: "red" }}>Coming Soon</p>
              <div className="pt-2">
                <a className="btm1 p-1 mr-2" href="/storage" style={{ textDecoration: "none" }}>
                  Reserve a Spot
                </a>
              </div>
            </div>
          </div>
          {/* <div className='col-md-6 d-flex justify-content-center'>
          <div className='aboutBOX bxs2 p-2 pb-3'>
            <img className="pb-2" src={Ad} width="70" />
            <h6 className='text-center textColor'><b>Advertising</b></h6>
            <p className='text-center' style={{ color: "red" }}>Summer 2024</p>
            {/* <button className="btm1 mr-2">
              <a href="/ads" style={{ textDecoration: "none" }}>
                View Tiers
              </a>
            </button> 
          </div>
        </div> */}
        </div>
        <div className="row">
          <div className='col-md-12 d-flex justify-content-center'>
            <div className='aboutBOX p-2 pt-4' >
              <h6 className='text-center textColor'><b>Sign Up</b></h6>
              <p className='text-center textColor'>Get the latest news and deals</p>
              <a className="btm1 p-1 mr-2" onClick={showModal} style={{ textDecoration: "none" }}>
                Here
              </a>
              <Modal title="Sign Up" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
                <Button form="myForm" key="submit" htmlType="submit">
                  Submit
                </Button>
              ]}>
                <Form id="myForm" className='p-2' layout='vertical' onFinish={onFinish}>
                  <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="phone" label="Phone Number">
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout >
  )
}

export default Home; 
