import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from "react-redux";
import DefaultLayout from '../components/DefaultLayout'
import { signUp } from '../redux/actions/customerActions'
import { Divider, Form, Input } from 'antd';
import wash from '../assests/car_wash.png';
import renew from '../assests/renew.png';
import ignition from '../assests/ignition.png';

function Park() {
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = "Austin Vehicle Storage & Long Term Parking | Rora"
    }, [])

    const { TextArea } = Input;

    function onFinish(values) {
        values.reason = "storage";
        dispatch(signUp(values))
    }

    return (
        <DefaultLayout>
            <div className='container textColor'>
                <div className='bxs p-3 mt-3'>
                    <h3>Vehicle Storage<br/>and<br/>Long Term Parking</h3>
                    <Divider />
                    <div className="row p-3 pb-5 pt-3 mt-2">
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className='pb-3' src={renew} width="100" />
                                <h5>Subscription Based</h5>
                                <Divider/>
                                <p className='text-center pb-4'>$175-250/month.<br/></p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className='pb-3' src={ignition} width="100" />
                                <h5>Weekly Start Ups</h5>
                                <Divider/>
                                <p className='text-center pb-4'>Monitoring &amp; Maintaining car battery life.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className='abtIcon p-3'>
                                <img className='pb-3' src={wash} width="100" />
                                <h5>Monthly Washes</h5>
                                <Divider/>
                                <p className='text-center pb-4'>Free with your subscription.</p>
                            </div>
                        </div>
                    </div>
                    <a className="btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">Request a Spot</a>
                    <div className='collapse' id="collapseExample">
                        <Form className='p-2' layout='vertical' onFinish={onFinish}>
                            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label="Phone Number">
                                <Input />
                            </Form.Item>
                            <Form.Item name="comment" label="Vehicle &amp; Expected Storage Time Length" rules={[{ required: true }]}>
                                <TextArea />
                            </Form.Item>
                            <div className='d-flex justify-content-end'>
                                <button className='btm1'>Submit</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Park