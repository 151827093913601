const initialData = {
    customers : []
};

export const customersReducer = (state=initialData ,  action)=>{
    
switch(action.type)
{
    case 'GET_ALL_CUSTOMERS' : {
        return{
            ...state,
            customers : action.payload
        }
    }

    default: return state
}

}