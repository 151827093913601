const initialData = {
    rides : []
};

export const ridesReducer = (state=initialData ,  action)=>{

switch(action.type)
{
    case 'GET_ALL_RIDES' : {
        return{
            ...state,
            rides : action.payload
        }
    }

    default: return state
}

}

